import { DEFAULT_TRANSITION_TIMEOUT } from '@clientweb/src/AppRoot/useAppRootController';
import avatar from '@clientweb/src/assets/avatar.svg';
import Spinner from '@clientweb/src/assets/progress-spinner-primary.svg';
import SamplefestLogo from '@clientweb/src/design/v1/general/SamplefestLogo/SamplefestLogo';
import {
    TileLine1Img,
    TileLine2Img,
    TileLineWrapper,
} from '@clientweb/src/design/v1/misc/TileLines/TileLine';
import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export interface ITransitionScanSuccessfulProps {
    className?: string;
}

const TransitionScanSuccessful = ({
    className,
}: ITransitionScanSuccessfulProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/products');
        }, DEFAULT_TRANSITION_TIMEOUT);
    }, [navigate]);

    return (
        <div className={className}>
            <TileLineWrapper src={TileLine1Img} />
            <section className="pb-16 self-center flex flex-col gap-2 items-center max-w-[350px]">
                <div className="p-2 bg-white rounded-full w-fit">
                    <img width={72} height={72} src={avatar} alt="avatar" />
                </div>
                <div className="w-fit">
                    <SamplefestLogo
                        classNameRetailer="h-14"
                        withoutOrganization
                    />
                </div>

                <h1 className="text-[38px] text-center leading-[44px] font-bold">
                    [The message goes here!]
                </h1>
                <img
                    src={Spinner}
                    alt="spinner"
                    className="size-7 animate-spin mt-2"
                />
            </section>
            <TileLineWrapper src={TileLine2Img} />
        </div>
    );
};

const Memoized = memo(TransitionScanSuccessful);
const Styled = styled(Memoized)`
    background-color: #f3f9d0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;
export default Styled;

import { memo } from 'react';
import styled from 'styled-components';

const ProgressBar = ({
    currentStep,
    totalSteps,
    isRounded,
}: {
    currentStep: number;
    totalSteps: number;
    isRounded?: boolean;
}) => {
    const barWidth = (currentStep / totalSteps) * 100;
    return (
        <div
            className={`w-full bg-primary-100 h-2 ${
                isRounded ? 'rounded-full' : ''
            }`}
        >
            <div
                className={`bg-primary-800 h-2 ${
                    isRounded ? 'rounded-full' : 'rounded-r-md'
                }`}
                style={{ width: `${barWidth}%` }}
            />
        </div>
    );
};

const Memoized = memo(ProgressBar);
const Styled = styled(Memoized)``;
export default Styled;

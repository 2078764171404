import * as Sentry from '@sentry/react';
import 'bulma/css/versions/bulma-no-dark-mode.min.css';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import 'tailwindcss/tailwind.css';

import RootRouter from './routes/Router';

Sentry.init({
    enabled: ['production', 'qa'].includes(
        import.meta.env['VITE_SAMPLE_FEST_ENVIRONMENT'],
    ),
    release: import.meta.env['VITE_GIT_HASH'],
    environment: import.meta.env['VITE_SAMPLE_FEST_ENVIRONMENT'],
    dsn: import.meta.env['VITE_SAMPLE_FEST_SENTRY_FRONTEND_DSN'],
    integrations: [
        Sentry.replayIntegration({
            // Additional Replay configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
        }),
        Sentry.replayCanvasIntegration(),
        Sentry.browserTracingIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

//@ts-expect-error Debug info for us
window.debugEnv = import.meta.env;

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);
root.render(
    <StrictMode>
        <RootRouter />
    </StrictMode>,
);

import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import Box from '@clientweb/src/components/Box/Box';
import FullscreenModalPopUp from '@clientweb/src/components/FullscreenModalPopUp/FullscreenModalPopUp';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface ICampaignQuestionPopUpProps {
    className?: string;
    onClose?: () => void;
    campaignId: string;
}

const CampaignQuestionPopUp = ({
    className,
    onClose,
    campaignId,
}: ICampaignQuestionPopUpProps) => {
    const appRootController = useContext(AppRootContext);

    return (
        <FullscreenModalPopUp className={className}>
            <Box>
                <p
                    onClick={onClose}
                    className="text-primary-800 absolute top-[20px] right-[20px] font-medium font-outfit"
                >
                    Skip for now
                </p>
                <div className="flex flex-col gap-2 items-left h-full">
                    <img
                        src={appRootController?.whiteLabelLogoUrl}
                        className="size-[80px] md:size-24 lg:size-32"
                        alt="retailer-logo"
                    />
                    <p className="text-left text-[2rem] font-bold leading-[44px] text-dark">
                        We would really appreciate your help!
                    </p>
                    <p className="text-[20px] font-normal	 text-left leading-[44px] text-dark">
                        This will take less than a minute.
                    </p>
                    <Link to={`/questionnaire/${campaignId}/review/`}>
                        <Button type="submit" className="uppercase">
                            Let&apos;s go
                        </Button>
                    </Link>
                </div>
            </Box>
        </FullscreenModalPopUp>
    );
};

const Memoized = memo(CampaignQuestionPopUp);
const Styled = styled(Memoized)`
    ${Box} {
        width: 90vw;
        overflow: scroll;
    }
`;
export default Styled;

import { directusFileUrl } from '@clientweb/src/utils/frontend';
import Carousel from 'react-multi-carousel';
import styled from 'styled-components';

const CAROUSEL_RESPONSIVE_SETUP = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const ProductCarousel = styled(
    ({
        className,
        images,
    }: {
        className?: string;
        images: Array<{
            __typename?: 'SampleFestProduct_files';
            directus_files_id?: {
                __typename?: 'directus_files';
                id: string;
            } | null;
        } | null> | null;
    }) => {
        const productImages = images?.map((item) => {
            const fileUrl = directusFileUrl(item?.directus_files_id);
            return fileUrl;
        });

        return (
            <div className={className}>
                <Carousel
                    showDots
                    arrows={false}
                    ssr={false}
                    infinite
                    customTransition="all 1s"
                    responsive={CAROUSEL_RESPONSIVE_SETUP}
                    itemClass="product-carousel-item"
                    customDot={<CustomDot />}
                >
                    {productImages?.map((image) => {
                        return (
                            <div key={image}>
                                <img
                                    alt="product thumbnail"
                                    src={image ?? ''}
                                    className="w-full h-[280px] rounded-md"
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </div>
        );
    },
)`
    padding: 0;
    width: 100%;
    height: 280px;

    .product-carousel-item {
        overflow: hidden;
    }

    .react-multi-carousel-dot-list {
        background-color: rgba(10, 10, 10, 1);
        width: fit-content;
        padding: 8px;
        border-radius: 24px;
        bottom: 10px;
        margin-left: auto;
        margin-right: 10px;
        gap: 6px;
        .react-multi-carousel-dot {
        }
    }
`;

const CustomDot = styled(({ className, onClick }) => {
    return <div className={className} onClick={onClick} />;
})`
    width: 8px;
    height: 8px;
    background-color: rgba(168, 167, 168, 1);
    border-radius: 100%;
    cursor: pointer;

    ${({ active }) =>
        active
            ? `
    background-color: #fff;
    `
            : ``}
`;

export default ProductCarousel;

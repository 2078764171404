import { Input } from '@clientweb/src/design/v1/dataEntry/Input/Input';
import { Loader } from '@googlemaps/js-api-loader';
import React, { useEffect, useRef } from 'react';

import IconLocation from './../../../assets/location_on.svg?react';

// Define the types for the place object and component props
interface PlaceResult {
    address_components?: google.maps.GeocoderAddressComponent[];
    geometry?: google.maps.places.PlaceGeometry;
    formatted_address?: string;
}

interface AddressAutocompleteInputProps {
    onPlaceSelected?: (place: PlaceResult) => void;
}

const AddressAutocompleteInput: React.FC<AddressAutocompleteInputProps> = ({
    onPlaceSelected,
    ...rest
}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const loader = new Loader({
            apiKey: import.meta.env['VITE_GOOGLE_MAPS_API'], // Replace with your actual API key
            version: 'weekly',
            libraries: ['places'], // Load the 'places' library for Autocomplete
        });

        (async () => {
            await loader.load();
            if (!inputRef.current) {
                return;
            }
            const autocompleteInstance = new google.maps.places.Autocomplete(
                inputRef.current,
                {
                    fields: ['ALL'],
                    types: ['address'],
                },
            );

            autocompleteInstance.addListener('place_changed', () => {
                const place = autocompleteInstance.getPlace() as PlaceResult;
                onPlaceSelected && onPlaceSelected(place); // Pass selected place to parent component
            });
        })();
    }, [onPlaceSelected]);

    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const { latitude, longitude } = position.coords;
                    const latLng = { lat: latitude, lng: longitude };

                    try {
                        // Load the Google Maps API
                        const loader = new Loader({
                            apiKey: import.meta.env['VITE_GOOGLE_MAPS_API'], // Replace with your Google Maps API key
                            version: 'weekly',
                            libraries: ['places'],
                        });

                        await loader.load();

                        // Initialize Geocoder
                        const geocoder = new google.maps.Geocoder();

                        // Geocode the latitude and longitude
                        geocoder.geocode(
                            { location: latLng },
                            (results, status) => {
                                if (status !== 'OK') return;
                                if (!results) return;
                                if ((results?.length ?? 0) <= 0) return;
                                const [finalResult] = results;
                                if (!finalResult) return;
                                onPlaceSelected && onPlaceSelected(finalResult);
                            },
                        );
                    } catch (e) {
                        console.error('Google Maps API load error:', e);
                    }
                },
                (error) => {
                    console.error('Geolocation error:', error);
                },
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    return (
        <div>
            <div className="relative">
                <Input
                    type="text"
                    // @ts-expect-error TODO fix props
                    isError={!!rest?.errors?.addressLine1}
                    placeholder="Search for a location"
                    autoComplete="address-line1"
                    {...rest}
                    ref={inputRef}
                />
                <div
                    onClick={handleGetLocation}
                    className=" absolute top-1/2 right-5 size-6  -translate-y-1/2 "
                >
                    <IconLocation />
                </div>
            </div>
        </div>
    );
};

export default AddressAutocompleteInput;

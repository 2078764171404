import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { ReviewContext } from '../ReviewProduct';
import CustomCheckbox from './CustomCheckbox';
import TextArea from './TextArea';

const ReviewQuestionString = styled(() => {
    const reviewController = useContext(ReviewContext);
    const [noAnswerCheckboxValue, setNoAnswerCheckboxValue] = useState(
        reviewController?.currentQuestion?.SampleFestProductQuestions_id
            ?.no_answer
            ? reviewController?.answerForCurrentQuestion?.noAnswer
            : false,
    );
    const [value, setValue] = useState<string>(
        (reviewController?.answerForCurrentQuestion?.answers as string) ?? '',
    );

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (event.target.checked) {
            setValue('');
        }
        setNoAnswerCheckboxValue(event.target.checked);
    };

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(event.target.value);
        setNoAnswerCheckboxValue(false);
    };

    useEffect(() => {
        const onSave = () => {
            reviewController?.saveQuestion({
                noAnswer: noAnswerCheckboxValue ? true : null,
                value,
            });
            reviewController?.continueFlow();
        };
        reviewController?.mittEventEmitter?.on('save', onSave);

        return () => reviewController?.mittEventEmitter?.off('save', onSave);
    }, [reviewController, noAnswerCheckboxValue, value]);

    return (
        <div className="flex flex-col justify-center items-start gap-2">
            <TextArea
                placeholder="Comments"
                handleChange={handleChange}
                value={value}
                disabled={!!noAnswerCheckboxValue}
            />
            {reviewController?.currentQuestion?.SampleFestProductQuestions_id
                ?.no_answer ? (
                <CustomCheckbox
                    type="single"
                    item={
                        reviewController?.currentQuestion
                            ?.SampleFestProductQuestions_id?.no_answer_text ??
                        ''
                    }
                    handleChange={handleCheckboxChange}
                    selectedOption={
                        noAnswerCheckboxValue
                            ? reviewController?.currentQuestion
                                  ?.SampleFestProductQuestions_id
                                  ?.no_answer_text ?? ''
                            : ''
                    }
                />
            ) : null}
        </div>
    );
})``;

export default ReviewQuestionString;

import ReviewStep from '@clientweb/src/routes/Questionnaire/ReviewProduct/ReviewStep';
import useReviewController from '@clientweb/src/routes/Questionnaire/ReviewProduct/useReviewController';
import { createContext, memo } from 'react';
import styled from 'styled-components';

export interface IReviewProps {
    className?: string;
}

export const ReviewContext = createContext<ReturnType<
    typeof useReviewController
> | null>(null);

const Review = ({ className }: IReviewProps) => {
    const reviewController = useReviewController();

    return (
        <ReviewContext.Provider value={reviewController}>
            <div className={className}>
                <ReviewStep />
            </div>
        </ReviewContext.Provider>
    );
};

const Memoized = memo(Review);
const Styled = styled(Memoized)`
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
`;
export default Styled;

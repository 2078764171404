import SamplefestLogoBackground from '@clientweb/src/assets/samplefest_logo_background.png';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import React, { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

const InstallPWAButton = ({ className }: { className?: string }) => {
    const [deferredPrompt, setDeferredPrompt] = useState<
        BeforeInstallPromptEvent | null | undefined
    >(window.deferredPrompt);
    const [isInstalled, setIsInstalled] = useState(false);

    useEffect(() => {
        // Check if the app is already installed
        const checkInstalled = () => {
            if (
                window?.matchMedia('(display-mode: standalone)')?.matches ||
                window?.navigator?.standalone
            ) {
                setIsInstalled(true);
            }
        };

        checkInstalled();

        // Listen for the beforeinstallprompt event
        const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
            console.log('handleBeforeInstallPrompt');
            event.preventDefault(); // Prevent the default mini-infobar
            setDeferredPrompt(event);
        };

        window?.addEventListener(
            'beforeinstallprompt',
            handleBeforeInstallPrompt,
        );
        window.addEventListener('appinstalled', () => {
            setIsInstalled(true); // Update state when the app is installed
        });

        return () => {
            window?.removeEventListener(
                'beforeinstallprompt',
                handleBeforeInstallPrompt,
            );
            window?.removeEventListener('appinstalled', () =>
                setIsInstalled(true),
            );
        };
    }, []);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Show the installation prompt
            const choiceResult = await deferredPrompt.userChoice;
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the installation prompt');
            } else {
                console.log('User dismissed the installation prompt');
            }
            setDeferredPrompt(null); // Reset the deferred prompt
        }
    };
    if (isInstalled || !deferredPrompt) {
        return null; // Don't show the button if the app is installed or not installable
    }

    return (
        <div className={className}>
            <span className="text-[#dee378] font-outfit font-bold text-xl">
                Loving Samplefest?
            </span>
            <p className="w-4/6 text-sm text-white">
                This is copy about how the user should install the app or any
                other subtext supporting the relevant text above
            </p>
            <img
                className="absolute top-[1rem] right-[1rem] h-4/5"
                src={SamplefestLogoBackground}
                alt=""
            />
            <Button
                className="w-[200px]"
                type="button"
                variant="secondary"
                onClick={handleInstallClick}
            >
                Install app
            </Button>
        </div>
    );
};

export const Styled = styled(InstallPWAButton)`
    position: relative;
    padding: 1.5rem;
    border-radius: 8px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background: #03594d;
`;

const Memoized = memo(Styled);
export default Memoized;

import FullscreenLoader from '@clientweb/src/components/FullscreenLoader/FullscreenLoader';
import BoxMessage from '@clientweb/src/design/v1/misc/BoxMessage/BoxMessage';
import RenderRewards from '@clientweb/src/routes/Rewards/RenderRewards';
import useRewardController from '@clientweb/src/routes/Rewards/useRewardsController';
import { createContext, memo } from 'react';
import styled from 'styled-components';

export interface IRewardsProps {
    className?: string;
}

export const RewardContext = createContext<ReturnType<
    typeof useRewardController
> | null>(null);

const Rewards = ({ className }: IRewardsProps) => {
    const rewardController = useRewardController();
    const isAnyReward = true;

    if (rewardController?.getUserRewardsQuery?.isFetching)
        return <FullscreenLoader />;

    return (
        <RewardContext.Provider value={rewardController}>
            <div className={className}>
                {isAnyReward ? (
                    <RenderRewards />
                ) : (
                    <BoxMessage
                        className="pb-16"
                        titleText="Review products. Win rewards"
                        instructionText="This is text that explains instructions on how to scan the QR code on the box"
                    />
                )}
            </div>
        </RewardContext.Provider>
    );
};

const Memoized = memo(Rewards);
const Styled = styled(Memoized)`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`;
export default Styled;

import UserRegister from '@clientweb/src/components/Forms/UserRegister';
import ProgressBar from '@clientweb/src/design/v1/ProgressBar/ProgressBar';
import SamplefestLogo from '@clientweb/src/design/v1/general/SamplefestLogo/SamplefestLogo';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Register: React.FC = () => {
    const [isRegisterFinished, setIsRegisterFinished] =
        useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (isRegisterFinished) {
            navigate('/products');
        }
    }, [isRegisterFinished, navigate]);

    return (
        <div className="container flex flex-col gap-3">
            <div className="flex justify-between items-center gap-3 pt-5">
                <SamplefestLogo />
                <div className="flex justify-end w-full max-w-24">
                    <ProgressBar currentStep={4} totalSteps={5} isRounded />
                </div>
            </div>
            <div className="flex flex-col gap-1 text-primary-800">
                <h2 className="text-3xl font-bold">Tell us about yourself!</h2>
                <p className="text-base">
                    This is copy about how the user has received a sample box
                    and what this experience is about.
                </p>
            </div>
            <UserRegister setIsRegisterFinished={setIsRegisterFinished} />
        </div>
    );
};

export default Register;

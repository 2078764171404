import {
    AppRootContext,
    useAppRoot,
} from '@clientweb/src/AppRoot/useAppRootController';
import { Suspense } from 'react';

import TRPCProvider from './TRPCProvider';

const TopDataLayer = ({ children }: { children?: React.ReactNode }) => {
    return (
        <TRPCProvider>
            <AppDataLayer>{children}</AppDataLayer>
        </TRPCProvider>
    );
};

const AppDataLayer = ({
    children,
}: {
    children?: React.ReactNode | React.ReactNode[];
}) => {
    const appRootController = useAppRoot();

    return (
        <AppRootContext.Provider value={appRootController ?? null}>
            <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>
        </AppRootContext.Provider>
    );
};

export default TopDataLayer;

import React, { memo } from 'react';
import styled from 'styled-components';

export interface IAuthLayoutProps {
    className?: string;
    children?: React.ReactNode;
}

const AuthLayout = ({ className, children }: IAuthLayoutProps) => {
    return (
        <div
            className={`h-screen overflow-x-hidden w-full bg-secondary-bg px-4 xs:!px-12 ${className}`}
        >
            {children}
        </div>
    );
};

const Memoized = memo(AuthLayout);
const Styled = styled(Memoized)``;
export default Styled;

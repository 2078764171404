import { trpcReact } from '@clientweb/src/AppRoot/trpcClient.react';

export const useRewardController = () => {
    const getUserRewardsQuery =
        trpcReact.productRouter.v1.getUserRewards.useQuery();

    return { getUserRewardsQuery };
};

export default useRewardController;

import TimerIcon from '@clientweb/src/assets/timer.svg';
import InputOTP from '@clientweb/src/design/v1/dataEntry/InputOTP/InputOTP';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { useTimerCountdown } from '@clientweb/src/utils/react';

import {
    useLoginWithPhoneController,
    OTP_LENGTH,
} from './useLoginWithPhoneController';

const OTPForm = ({
    controller,
}: {
    controller: ReturnType<typeof useLoginWithPhoneController>;
}) => {
    const [timer, resetTimer] = useTimerCountdown();
    return (
        <form onSubmit={controller.handleVerifyCode} ref={controller.otpRef}>
            <InputOTP
                codeLength={OTP_LENGTH}
                onSubmit={controller.onOTPDone}
                error={controller.error}
            />

            <div className="flex gap-3 my-5 items-center">
                <div className="flex gap-1 items-center">
                    <img
                        src={TimerIcon}
                        alt="timer"
                        className="size-[17px] mb-[1px]"
                    />
                    <p className="text-primary-800 text-xl font-bold">
                        {timer}s
                    </p>
                </div>

                <button
                    disabled={controller.isLoading || timer !== 0}
                    onClick={resetTimer}
                    type="button"
                    className="bg-transparent text-xl font-bold text-primary-800 disabled:cursor-not-allowed disabled:text-primary-400"
                >
                    Resend Code
                </button>
            </div>
            <Button
                isLoading={controller.isLoading}
                type="submit"
                className="mt-1"
            >
                NEXT
            </Button>
        </form>
    );
};

export default OTPForm;

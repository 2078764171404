import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import GetLocation from '@clientweb/src/components/GoogleMaps/GetLocation/GetLocation';
import PlacesAutocomplete from '@clientweb/src/components/GoogleMaps/PlacesAutocomplete/PlacesAutocomplete';
import { Input } from '@clientweb/src/design/v1/dataEntry/Input/Input';
import Select from '@clientweb/src/design/v1/dataEntry/Select/Select';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { firebaseClientAppAuth as auth } from '@clientweb/src/firebase.client';
import { zodResolver } from '@hookform/resolvers/zod';
import { memo, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

import { CRM_STATE_LIST } from '@common/commonData/crmStateList';

interface IProps {
    closeModal: () => void;
    handleError: (field: string) => void;
    handleUpdate: () => void;
}

const userAddressSchema = z.object({
    addressLine1: z.string(),
    addressLine2: z.string(),
    city: z.string(),
    state: z.string(),
    zipCode: z.string(),
});

type FormDataUserAddress = z.infer<typeof userAddressSchema>;

const UserAddressForm = ({ closeModal, handleError, handleUpdate }: IProps) => {
    const appRootControl = useContext(AppRootContext);
    const [error, setError] = useState<string | null>(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormDataUserAddress>({
        defaultValues: {
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            zipCode: '',
        },
        resolver: zodResolver(userAddressSchema),
    });

    useEffect(() => {
        const user = appRootControl?.getUserProfileQuery.data;
        setValue('addressLine1', user?.addressLine1 ?? '');
        setValue('addressLine2', user?.addressLine2 ?? '');
        setValue('city', user?.city ?? '');
        setValue('state', user?.state ?? '');
        setValue('zipCode', user?.zipCode ?? '');
    }, [appRootControl?.getUserProfileQuery.data]);

    const onSubmit = async (data: FormDataUserAddress) => {
        const user = await trpcVanilla.userRouter.v1.get.query({
            firebaseId: auth.currentUser?.uid,
        });

        if (!user) {
            setError('User not found');
            return;
        }

        try {
            await trpcVanilla.userRouter.v1.updateUserAddress.mutate({
                userId: user.id,
                addressLine1: data.addressLine1,
                addressLine2: data.addressLine2,
                city: data.city,
                state: data.state,
                zipCode: data.zipCode,
            });
        } catch (error) {
            handleError('Address');
        }

        appRootControl?.getUserProfileQuery.refetch();
        handleUpdate();
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
                <label
                    htmlFor="addressLine1"
                    className="text-primary-800 font-bold"
                >
                    Address Line 1
                </label>
                <Controller
                    control={control}
                    name="addressLine1"
                    render={({ field }) => (
                        <Input
                            type="text"
                            autoComplete="address-line1"
                            {...field}
                        />
                    )}
                />
                {errors.addressLine1 && (
                    <p className="help is-danger font-outfit">
                        Address Line 1 is required
                    </p>
                )}
            </div>

            <div className="field">
                <label
                    htmlFor="addressLine2"
                    className="text-primary-800 font-bold"
                >
                    Address Line 2
                </label>
                <Controller
                    control={control}
                    name="addressLine2"
                    render={({ field }) => (
                        <Input
                            type="text"
                            autoComplete="address-line2"
                            {...field}
                        />
                    )}
                />
                {errors.addressLine2 && (
                    <p className="help is-danger font-outfit">
                        Address Line 2 is required
                    </p>
                )}
            </div>

            <div className="field">
                <label htmlFor="city" className="text-primary-800 font-bold">
                    City
                </label>
                <Controller
                    control={control}
                    name="city"
                    render={({ field }) => (
                        <Input type="text" autoComplete="city" {...field} />
                    )}
                />
                {errors.city && (
                    <p className="help is-danger font-outfit">
                        City is required
                    </p>
                )}
            </div>
            <div className="flex gap-4">
                <div className="field">
                    <label
                        htmlFor="state"
                        className="text-primary-800 font-bold"
                    >
                        State
                    </label>
                    <Controller
                        control={control}
                        name="state"
                        render={({ field }) => (
                            <Select
                                {...field}
                                autoComplete="state"
                                placeholder="State"
                            >
                                {CRM_STATE_LIST.map((state) => (
                                    <option
                                        key={state.stateKey}
                                        value={state.stateKey}
                                    >
                                        {state.fullName}
                                    </option>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.city && (
                        <p className="help is-danger font-outfit">
                            State is required
                        </p>
                    )}
                </div>
                <div className="field w-[40%]">
                    <label
                        htmlFor="zipCode"
                        className="text-primary-800 font-bold"
                    >
                        Zip
                    </label>
                    <Controller
                        control={control}
                        name="zipCode"
                        render={({ field }) => (
                            <Input
                                type="text"
                                autoComplete="postal-code"
                                {...field}
                            />
                        )}
                    />
                    {errors.city && (
                        <p className="help is-danger font-outfit">
                            Zip code is required
                        </p>
                    )}
                </div>
            </div>

            {error && <p className="help is-danger font-outfit">{error}</p>}

            <div className="flex gap-2">
                <Button type="button" variant="secondary" onClick={closeModal}>
                    CANCEL
                </Button>
                <Button type="submit">SAVE</Button>
            </div>
        </form>
    );
};

const Memoized = memo(UserAddressForm);
export default Memoized;

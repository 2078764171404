import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';

export interface SelectProps
    extends React.SelectHTMLAttributes<HTMLSelectElement> {
    placeholder: string;
    isError?: boolean;
    className?: string;
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
    ({ className, placeholder, children, isError, ...props }, ref) => {
        return (
            <div
                className={clsx(
                    'select text-xl text-dark',
                    isError ? 'is-danger' : 'is-primary',
                    className,
                )}
            >
                <select {...props} ref={ref} className="w-full text-dark">
                    <option className="text-primary-800"></option>
                    {children}
                </select>
            </div>
        );
    },
);

const Styled = styled(Select)`
    --bulma-primary: rgb(65 64 66 /1);

    & select {
        background-color: white;
        color: var(--bulma-primary);
        border-color: #81aca6;
        padding: 9px 14px !important;

        &:focus {
            border-color: #004d40;
            box-shadow: none;
        }
    }

    &.is-danger select {
        border-color: #f39e9b;
    }
`;

export default Styled;

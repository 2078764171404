import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import FullscreenLoader from '@clientweb/src/components/FullscreenLoader/FullscreenLoader';
import GlobalStyles from '@clientweb/src/routes/layouts/globalStyles';
import Hotjar from '@hotjar/browser';
import { SnackbarProvider } from 'notistack';
import { MaterialDesignContent } from 'notistack';
import React, { memo, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import 'react-multi-carousel/lib/styles.css';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import NotiCheckSVG from '../../assets/Check.svg?react';
import ErrorSVG from '../../assets/error.svg?react';

export interface IRootLayoutProps {
    className?: string;
    children?: React.ReactNode;
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
        backgroundColor: '#212021',
    },
    '&.notistack-MuiContent-error': {
        backgroundColor: '#E84F48',
    },
}));

const RootLayout = ({ className, children }: IRootLayoutProps) => {
    const appRootController = useContext(AppRootContext);
    const location = useLocation();
    const env = import.meta.env['VITE_SAMPLE_FEST_ENVIRONMENT'];

    useEffect(() => {
        console.log(`Version: ${import.meta.env['VITE_GIT_HASH']}`);
        console.log(`Env: ${env}`);
        console.log(
            `Organization: ${appRootController?.identifiedOrganization}`,
        );
        (() => {
            switch (env) {
                case 'development':
                    document.title = `[DEV][${appRootController?.identifiedOrganization}] Samplefest`;

                    return;
                case 'qa':
                    document.title = `[QA][${appRootController?.identifiedOrganization}] Samplefest`;
                    ReactGA.initialize(
                        import.meta.env['VITE_GOOGLE_ANALYTICS_ID_QA'],
                    );
                    return;
                default:
                    Hotjar.init(5204722, 6);
                    ReactGA.initialize(
                        import.meta.env['VITE_GOOGLE_ANALYTICS_ID_PROD'],
                    );
                    return;
            }
        })();
    }, [env, appRootController?.identifiedOrganization]);

    if (appRootController?.state.isLoading) {
        return <FullscreenLoader />;
    }

    return (
        <div className={className}>
            <GlobalStyles />
            <SnackbarProvider
                Components={{
                    success: StyledMaterialDesignContent,
                    error: StyledMaterialDesignContent,
                }}
                autoHideDuration={3000}
                iconVariant={{
                    success: <NotiCheckSVG />,
                    error: <ErrorSVG />,
                    warning: '⚠️ ',
                    info: 'ℹ️ ',
                }}
                domRoot={document.getElementById('toast-root') ?? document.body}
                style={{
                    marginBottom:
                        location.pathname === '/profile' ? '75px' : '0px',
                    width: location.pathname === '/profile' ? '95vw' : '100%',
                }}
            >
                {children}
            </SnackbarProvider>
        </div>
    );
};

const Memoized = memo(RootLayout);
const Styled = styled(Memoized)``;
export default Styled;

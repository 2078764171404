import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import { Input } from '@clientweb/src/design/v1/dataEntry/Input/Input';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { firebaseClientAppAuth as auth } from '@clientweb/src/firebase.client';
import { zodResolver } from '@hookform/resolvers/zod';
import { int } from 'drizzle-orm/mysql-core';
import { memo, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

const userCredentialsSchema = z.object({
    name: z.string(),
});

interface IProps {
    closeModal: () => void;
    handleError: (field: string) => void;
    handleUpdate: () => void;
}

type FormDataUserCredentials = z.infer<typeof userCredentialsSchema>;

const UserCredentialsForm = ({
    closeModal,
    handleError,
    handleUpdate,
}: IProps) => {
    const appRootControl = useContext(AppRootContext);
    const [error, setError] = useState<string | null>(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormDataUserCredentials>({
        defaultValues: {
            name: '',
        },
        resolver: zodResolver(userCredentialsSchema),
    });

    useEffect(() => {
        setValue(
            'name',
            `${appRootControl?.getUserProfileQuery.data?.firstName} ${appRootControl?.getUserProfileQuery.data?.lastName}` ??
                '',
        );
    }, [appRootControl?.getUserProfileQuery.data]);

    const onSubmit = async (data: FormDataUserCredentials) => {
        const userId = auth.currentUser?.uid;

        if (!userId) {
            setError('User not found');
            return;
        }

        const nameParts = data.name.trim().split(' ');

        if (nameParts.length < 2 || !nameParts[0] || !nameParts[1]) {
            setError('Name is invalid');
            return;
        }
        const firstName = nameParts[0];
        const lastName = nameParts.slice(1).join(' ');

        try {
            await trpcVanilla.userRouter.v1.updateUserCredentials.mutate({
                userId,
                firstName: firstName,
                lastName: lastName,
            });
        } catch (error) {
            handleError('Name');
            return;
        }

        appRootControl?.getUserProfileQuery.refetch();
        handleUpdate();
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-1 mt-4"
        >
            <div className="field">
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <Input
                            type="text"
                            autoComplete="given-name"
                            {...field}
                        />
                    )}
                />
                {errors.name && (
                    <p className="help is-danger font-outfit">
                        Name is required
                    </p>
                )}
            </div>

            {error && <p className="help is-danger font-outfit">{error}</p>}

            <div className="flex gap-2">
                <Button
                    type="button"
                    onClick={closeModal}
                    variant="secondary"
                    className="w-1/2"
                >
                    CANCEL
                </Button>
                <Button type="submit" className="w-1/2">
                    SAVE
                </Button>
            </div>
        </form>
    );
};

const Memoized = memo(UserCredentialsForm);
export default Memoized;

import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import Logo from '@clientweb/src/assets/samplefest-logo.svg';
import clsx from 'clsx';
import { useContext } from 'react';

function SamplefestLogo({
    className,
    classNameRetailer,
    classNameOrganization,
    withoutOrganization,
}: {
    className?: string;
    classNameOrganization?: string;
    classNameRetailer?: string;
    withoutOrganization?: boolean;
}) {
    const appRootController = useContext(AppRootContext);
    // const navigate = useNavigate(); // just for dev for quick logout
    return (
        <div
            className={`${className} flex w-full items-center`}
            // onClick={() => navigate('/auth/logout')}
        >
            {!withoutOrganization && (
                <img
                    src={appRootController?.whiteLabelLogoUrl}
                    className={clsx([
                        'size-14 -mr-2 z-20',
                        classNameOrganization,
                    ])}
                    alt="organization-logo"
                />
            )}
            <img
                src={Logo}
                alt="retailer-logo"
                className={clsx(['h-10 max-w-full', classNameRetailer])}
            />
        </div>
    );
}

export default SamplefestLogo;

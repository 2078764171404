import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { firebaseClientAppAuth as auth } from '@clientweb/src/firebase.client';
import { zodResolver } from '@hookform/resolvers/zod';
import { memo, useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { z } from 'zod';

import { convertDateToUnixTimestamp } from '@common/utils/dateAndTime/formatting';

import CustomDatePicker from './CustomDatePicker';

interface IProps {
    closeModal: () => void;
    handleError: (field: string) => void;
    handleUpdate: () => void;
}

const userDOBSchema = z.object({
    dateOfBirth: z.date(),
});

type FormDataDOB = z.infer<typeof userDOBSchema>;

const UserAdditionalInfoForm = ({
    closeModal,
    handleError,
    handleUpdate,
}: IProps) => {
    const appRootControl = useContext(AppRootContext);
    const [error, setError] = useState<string | null>(null);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormDataDOB>({
        defaultValues: {
            dateOfBirth: new Date(),
        },
        resolver: zodResolver(userDOBSchema),
    });

    useEffect(() => {
        const user = appRootControl?.getUserProfileQuery.data;
        if (user?.dateOfBirth) {
            setValue(
                'dateOfBirth',
                new Date(user.dateOfBirth * 1000) ?? new Date(),
            );
        }
    }, [appRootControl?.getUserProfileQuery.data]);

    const onSubmit = async (data: FormDataDOB) => {
        const userId = auth.currentUser?.uid;

        if (!userId) {
            setError('User not found');
            return;
        }
        try {
            await trpcVanilla.userRouter.v1.updateUserDOB.mutate({
                userId,
                dateOfBirth: convertDateToUnixTimestamp(data.dateOfBirth),
            });
        } catch (error) {
            handleError('Date of Birth');
        }
        handleUpdate();
        appRootControl?.getUserProfileQuery.refetch();
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-1 mt-4"
        >
            <div className="field">
                <Controller
                    control={control}
                    name="dateOfBirth"
                    render={({ field }) => (
                        <CustomDatePicker
                            onChange={field.onChange}
                            value={field.value}
                        />
                    )}
                />
                {errors.dateOfBirth && (
                    <p className="help is-danger font-outfit">Invalid DOB</p>
                )}
            </div>
            {error && <p className="help is-danger font-outfit">{error}</p>}
            <div className="flex gap-2">
                <Button type="button" onClick={closeModal} variant="secondary">
                    CANCEL
                </Button>
                <Button type="submit">SAVE</Button>
            </div>
        </form>
    );
};

const Memoized = memo(UserAdditionalInfoForm);
const Styled = styled(Memoized)`
    .react-datepicker {
        width: 100% !important;
    }
`;
export default Memoized;

import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import Tile1 from '@clientweb/src/assets/Sample Box/Header/1.svg';
import Tile2 from '@clientweb/src/assets/Sample Box/Header/2.svg';
import Tile3 from '@clientweb/src/assets/Sample Box/Header/3.svg';
import Brandmark from '@clientweb/src/assets/Samplefest Brandmark.svg';
import InstallPWAButton from '@clientweb/src/components/Header/InstallPWAButton';
import Tabs from '@clientweb/src/components/Tabs/Tabs';
import { buttonVariants } from '@clientweb/src/design/v1/general/Button/Button';
import BoxMessage from '@clientweb/src/design/v1/misc/BoxMessage/BoxMessage';
import { cn, directusFileUrl } from '@clientweb/src/utils/frontend';
import { format } from 'date-fns';
import React, { createContext, memo, useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PRODUCT_STATUS } from '@common/business/product/types';

import GiftCardSVG from '../../assets/bottomNav/card_giftcard.svg?react';
import CheckCircleSVG from '../../assets/bottomNav/check_circle.svg?react';
import CrossIcon from '../../assets/bottomNav/cross-no-border.svg?react';
import ScanSVG from '../../assets/bottomNav/qr_code_scanner.svg?react';
import SuccessSVG from '../../assets/bottomNav/success.svg?react';
import AllProductReviewCompletePopUp from './AllProductReviewCompletePopUp';
import ProductCarousel from './ProductsCarousel';
import useProductsController from './useProductsController';

export interface IProductsProps {
    className?: string;
}

export const ProductControllerContext = createContext<ReturnType<
    typeof useProductsController
> | null>(null);

type Product =
    | Awaited<
          ReturnType<typeof trpcVanilla.productRouter.v1.getListForUser.query>
      >[number]['products'][number];

const sortByReviewStatus = (a: Product, b: Product) => {
    if (
        a.status === PRODUCT_STATUS.REVIEW_IN_PROGRESS &&
        b.status !== PRODUCT_STATUS.REVIEW_IN_PROGRESS
    ) {
        return -1;
    }
    if (
        a.status === PRODUCT_STATUS.NEW_PRODUCT &&
        b.status === PRODUCT_STATUS.REVIEW_DONE
    ) {
        return -1;
    } else if (
        a.status === PRODUCT_STATUS.REVIEW_DONE &&
        b.status === PRODUCT_STATUS.NEW_PRODUCT
    ) {
        return 1;
    }
    return 0;
};

const Products = ({ className }: IProductsProps) => {
    const productsController = useProductsController();

    const areAllProductsReviewed =
        productsController.searchParams.get('reviewAllSuccess') === 'true';

    return (
        <ProductControllerContext.Provider value={productsController}>
            <div
                className={cn(
                    `rounded-md pb-4 -mt-5`,
                    productsController.productListQuery.data?.length === 0
                        ? 'bg-primary-100'
                        : 'bg-chartreuse-100',
                    className,
                )}
            >
                <section className="flex flex-col">
                    {productsController.productListQuery?.data?.length === 0 ? (
                        <BoxMessage
                            className="pb-16"
                            titleText="Scan your box"
                            instructionText="This is text that explains instructions on how to scan the QR code on the box"
                            button={{
                                text: 'SCAN QR CODE',
                                link: '/scan',
                            }}
                        />
                    ) : (
                        productsController.productListQuery.data?.map(
                            (campaign) => {
                                const isProductReviewedFromCampaign =
                                    productsController.reviewSuccess &&
                                    productsController.searchParams.get(
                                        'campaignId',
                                    ) === campaign.campaign.id;
                                const reviewedProduct =
                                    isProductReviewedFromCampaign &&
                                    campaign.products.find((product) => {
                                        return (
                                            product.SampleFestProduct_id?.id ===
                                                productsController.searchParams.get(
                                                    'productId',
                                                ) &&
                                            product.status ===
                                                PRODUCT_STATUS.REVIEW_DONE
                                        );
                                    });
                                return (
                                    <ProductItem
                                        key={campaign.campaign.id}
                                        reviewedProductName={
                                            reviewedProduct
                                                ? reviewedProduct
                                                      .SampleFestProduct_id
                                                      ?.short_name
                                                : ''
                                        }
                                        campaign={campaign}
                                    />
                                );
                            },
                        )
                    )}
                </section>
            </div>
            <InstallPWAButton className="mt-4" />
            {areAllProductsReviewed && <AllProductReviewCompletePopUp />}
        </ProductControllerContext.Provider>
    );
};

const ProductItem = styled(
    ({
        className,
        campaign,
        reviewedProductName,
    }: {
        className?: string;
        reviewedProductName?: string | null;
        campaign: Awaited<
            ReturnType<typeof trpcVanilla.productRouter.v1.getListForUser.query>
        >[number];
    }) => {
        const [isReviewed, setIsReviewed] = useState(false);
        const productsToReview = campaign.products.filter(
            (product) => product.status !== PRODUCT_STATUS.REVIEW_DONE,
        ).length;

        useEffect(() => {
            let timeoutId: NodeJS.Timeout;
            if (reviewedProductName) {
                setIsReviewed(true);
                timeoutId = setTimeout(() => {
                    setIsReviewed(false);
                }, 5000);
            }
            return () => {
                clearTimeout(timeoutId);
            };
        }, [reviewedProductName]);

        return (
            <div className={className} key={campaign.campaign.id}>
                <div className="flex items-center relative p-5">
                    <div className="flex flex-col gap-1">
                        <p className="text-xl font-bold text-primary-800">
                            {campaign.campaign.name}
                        </p>
                        <p className="text-base text-dark">
                            {`Expires ${format(
                                new Date(campaign.campaign.review_until),
                                'MMM yyyy',
                            )}`}
                        </p>
                    </div>
                    <img
                        src={Tile1}
                        alt="tile 1"
                        className="absolute bottom-0 right-8"
                        width={93}
                        height={88}
                    />
                    <img
                        src={Tile2}
                        alt="tile 2"
                        className="absolute top-0 right-16"
                        width={92}
                        height={88}
                    />
                    <img
                        src={Tile3}
                        alt="tile 3"
                        className="absolute top-0 right-0"
                        width={82.5}
                        height={80}
                    />
                </div>
                <div className="relative overflow-hidden bg-white border border-primary-100 rounded-md w-[90%] p-4 mx-auto">
                    {isReviewed ? (
                        <Confetti
                            tweenDuration={5000}
                            numberOfPieces={400}
                            recycle={false}
                            className="relative"
                            drawShape={(ctx) => {
                                ctx.beginPath();
                                const width = Math.random() * 10 + 3; // Random width between 10-40
                                const height = Math.random() * 10 + 2; // Random height between 5-25

                                ctx.fillRect(
                                    -width / 2,
                                    -height / 2,
                                    width,
                                    height,
                                );
                                ctx.closePath();
                            }}
                        />
                    ) : null}

                    <div
                        className={`flex items-center justify-center gap-2 ${
                            isReviewed
                                ? 'bg-secondary-bright'
                                : 'bg-primary-100'
                        } rounded-md p-2`}
                    >
                        {React.cloneElement(
                            isReviewed ? <SuccessSVG /> : <GiftCardSVG />,
                            {
                                className: `size-[24px] text-primary-800 mb-1 ${
                                    !isReviewed ? 'animate-bounce-small' : ''
                                }`,
                            },
                        )}
                        {isReviewed ? (
                            <p className="font-bold font-outfit text-base text-dark overflow-hidden  text-ellipsis  text-nowrap max-w-64 ">{`${reviewedProductName} rewieved!`}</p>
                        ) : productsToReview === 0 ? (
                            <div>
                                <span className="font-bold font-outfit text-base text-dark">
                                    You earned a reward! &nbsp;
                                </span>
                                <Link to="/rewards">
                                    <span className=" underline font-bold font-outfit text-base text-dark">
                                        Redeem now
                                    </span>
                                </Link>
                            </div>
                        ) : (
                            <p className="font-bold font-outfit text-base text-dark">{`${productsToReview} products to review for your reward!`}</p>
                        )}
                    </div>
                    <ProductGrid
                        className="pt-4"
                        campaignId={campaign.campaign.id}
                        data={campaign.products}
                    />
                </div>
            </div>
        );
    },
)`
    position: relative;
`;

const ProductGrid = styled(
    ({
        className,
        data,
        isLoading,
        campaignId,
    }: {
        className?: string;
        campaignId: string;
        isLoading?: boolean;
        data: Product[] | undefined;
    }) => {
        const sortedData = data?.sort(sortByReviewStatus);
        return (
            <div className={`${className}`}>
                {data?.length === 0 && isLoading && <span>Loading...</span>}
                {data?.length === 0 && !isLoading && (
                    <span>Nothing to display</span>
                )}
                <section className="grid grid-cols-2 gap-4">
                    {sortedData?.map((product) => {
                        if (!product) return null;
                        const questionarieStatus =
                            product.status as unknown as PRODUCT_STATUS;
                        return (
                            <Product
                                key={product?.SampleFestProduct_id?.id}
                                campaignId={campaignId}
                                status={questionarieStatus}
                                data={product}
                            />
                        );
                    })}
                </section>
            </div>
        );
    },
)``;

const Product = styled(
    ({
        campaignId,
        className,
        data,
        status,
    }: {
        campaignId: string;
        status: PRODUCT_STATUS;
        className?: string;
        data?: NonNullable<Product>;
    }) => {
        const [isOpen, setIsOpen] = useState(false);
        const fileUrl = directusFileUrl(
            data?.SampleFestProduct_id?.main_image,
            {
                height: '160',
                width: '160',
            },
        );

        const onClick = () => {
            setIsOpen(true);
        };

        useEffect(() => {
            if (isOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'auto';
            }
            return () => {
                document.body.style.overflow = 'auto';
            };
        }, [isOpen]);

        const productStatus = (() => {
            switch (status) {
                case PRODUCT_STATUS.NEW_PRODUCT:
                    return 'Not reviewed';
                case PRODUCT_STATUS.REVIEW_IN_PROGRESS:
                    return 'In progress';
                case PRODUCT_STATUS.ERROR:
                    return "Error, can't review";
                default:
                    return 'Reviewed';
            }
        })();

        return (
            <div onClick={onClick} className={className}>
                <div className="productTile">
                    <div className="w-fit relative mx-auto rounded-md">
                        {data?.status === PRODUCT_STATUS.REVIEW_DONE &&
                            React.cloneElement(<CheckCircleSVG />, {
                                className: `size-[24px] text-primary-800 absolute top-3.5 right-3`,
                            })}
                        <img
                            alt="product thumbnail"
                            src={fileUrl ?? ''}
                            className="mx-auto border border-primary rounded-md p-2"
                            height={160}
                            width={160}
                        />
                    </div>
                </div>
                <span className="productName mt-3 text-center font-outfit">
                    {data?.SampleFestProduct_id?.short_name}
                </span>
                <span className="productStatus mx-auto px-2 py-1 mb-2 text-center text-sm rounded-md mt-auto">
                    {productStatus}
                </span>
                {isOpen && (
                    <div className="absolute inset-0 h-screen w-screen flex flex-col gap-4 p-5 pb-12 bg-white z-[102]">
                        <div className="flex justify-between items-center">
                            <h3 className="text-xl font-bold text-dark">
                                {data?.SampleFestProduct_id?.short_name}
                            </h3>
                            <button
                                className="text-dark"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsOpen(false);
                                }}
                            >
                                <CrossIcon />
                            </button>
                        </div>
                        <ProductCarousel
                            images={data?.SampleFestProduct_id?.images ?? []}
                        />
                        <Tabs
                            description={
                                data?.SampleFestProduct_id?.description ?? ''
                            }
                        />
                        <div className="fixed p-4 inset-x-0 bottom-0 w-full bg-white z-[200] flex justify-center shadow-product-details">
                            <Link
                                to={`/questionnaire/${campaignId}/${data?.SampleFestProduct_id?.id}/review/`}
                                className={buttonVariants({
                                    variant: 'primary',
                                })}
                            >
                                REVIEW PRODUCT
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        );
    },
)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 360px;
    min-width: 160px;
    gap: 4px;

    ${({ status }) => {
        switch (status) {
            case PRODUCT_STATUS.ERROR:
                return `
                    --main-color: red;
                    --secondary-color: white;
                `;
            case PRODUCT_STATUS.NEW_PRODUCT:
                return `
                    --main-color: #03594D;
                    --secondary-color: #D26500;
                    --secondary-background-color: #FEF4E7;
                `;
            case PRODUCT_STATUS.REVIEW_IN_PROGRESS:
                return `
                    --main-color: #03594D;
                    --secondary-color: #0292EC;
                    --secondary-background-color: #ECF8FF;

                `;
            default:
                return `
                    --main-color: #03594D;
                    --secondary-color: #236E63;
                    --secondary-background-color: #E6EEED;
                `;
        }
    }}

    .productStatus {
        background-color: var(--secondary-background-color);
        color: var(--secondary-color);
    }

    .productName {
        color: var(--main-color);
        font-size: 1rem;
        font-weight: 600;
        text-align: center;
        word-wrap: wrap;
        max-width: 80%;
    }

    .productTile {
        width: 100%;
        border-radius: 6px;
    }
`;

const Memoized = memo(Products);
const Styled = styled(Memoized)``;
export default Styled;

import TileLine1 from '@clientweb/src/assets/tile_line_1.png';
import ProgressBar from '@clientweb/src/design/v1/ProgressBar/ProgressBar';
import { Input } from '@clientweb/src/design/v1/dataEntry/Input/Input';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import SamplefestLogo from '@clientweb/src/design/v1/general/SamplefestLogo/SamplefestLogo';
import {
    TileLineWrapper,
    TileLine2Img,
} from '@clientweb/src/design/v1/misc/TileLines/TileLine';
import { memo } from 'react';
import styled from 'styled-components';

import OTPForm from './OTPForm';
import { useLoginWithPhoneController } from './useLoginWithPhoneController';

const LoginWithPhone = ({ className }: { className?: string }) => {
    const lognWithPhoneController = useLoginWithPhoneController();

    const formattedPhoneNumber =
        `${lognWithPhoneController.isdCodeConfig?.code}` +
        lognWithPhoneController.phoneNumber;

    return (
        <>
            <div
                className={`container flex flex-col gap-6 relative ${className}`}
            >
                <div className="flex justify-between items-center gap-3 pt-5">
                    <SamplefestLogo classNameRetailer="h-[32px]" />
                    <div className="flex justify-end w-full max-w-24">
                        <ProgressBar
                            currentStep={
                                lognWithPhoneController.isCodeSent ? 2 : 1
                            }
                            totalSteps={3}
                            isRounded
                        />
                    </div>
                </div>

                <div className="flex flex-col gap-1 text-primary-800">
                    <h2 className="text-3xl font-bold">
                        {lognWithPhoneController.isCodeSent
                            ? 'Enter the code'
                            : 'Welcome!'}
                    </h2>
                    <p className="text-base">
                        {lognWithPhoneController.isCodeSent
                            ? `We sent a 6 digit code to ${formattedPhoneNumber}`
                            : 'This is copy about how the user has received a sample box and what they’ll get out of filling the'}
                    </p>
                </div>
                <div className="columns is-centered">
                    <div className="column is-4">
                        {!lognWithPhoneController.isCodeSent ? (
                            <form
                                onSubmit={
                                    lognWithPhoneController.handleSendCode
                                }
                            >
                                <label
                                    htmlFor="phone"
                                    className="text-primary-800 font-bold"
                                >
                                    Phone Number
                                </label>
                                <div className="field mt-1">
                                    <div className="control">
                                        <Input
                                            name="phone"
                                            dimension="md"
                                            autoComplete="tel"
                                            isError={
                                                !!lognWithPhoneController.error
                                            }
                                            ref={
                                                lognWithPhoneController.phoneInputRef
                                            }
                                            type="tel"
                                            value={
                                                lognWithPhoneController
                                                    .isdCodeConfig?.code +
                                                lognWithPhoneController.phoneNumber
                                            }
                                            onChange={(e) => {
                                                const newValue =
                                                    e.target.value.replace(
                                                        lognWithPhoneController
                                                            .isdCodeConfig
                                                            ?.code ?? '',
                                                        '',
                                                    ); // Remove the ISD code part
                                                if (
                                                    newValue ===
                                                    lognWithPhoneController.isdCodeConfig?.code.replaceAll(
                                                        '-',
                                                        '',
                                                    ) //If the user has cleared the input field
                                                ) {
                                                    return;
                                                }
                                                const lastChar =
                                                    newValue[
                                                        newValue.length - 1
                                                    ] ?? '';

                                                if (newValue === '') {
                                                    lognWithPhoneController.setPhoneNumber(
                                                        '',
                                                    ); // Clear the input field
                                                }

                                                if (
                                                    newValue.length >
                                                    (lognWithPhoneController
                                                        .isdCodeConfig
                                                        ?.maxPhoneLength ?? 20)
                                                ) {
                                                    return;
                                                }

                                                // Check if the last character is a digit
                                                if (/^\d$/.test(lastChar)) {
                                                    lognWithPhoneController.setPhoneNumber(
                                                        newValue,
                                                    );
                                                }
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                {lognWithPhoneController.error && (
                                    <p className="help is-danger text-center font-outfit">
                                        {lognWithPhoneController.error}
                                    </p>
                                )}
                                {lognWithPhoneController.message && (
                                    <p className="help is-success">
                                        {lognWithPhoneController.message}
                                    </p>
                                )}
                                <div id="recaptcha-container"></div>
                                <div className="field mt-8">
                                    <div className="control">
                                        <Button
                                            isLoading={
                                                lognWithPhoneController.isLoading
                                            }
                                            type="submit"
                                        >
                                            NEXT
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <OTPForm controller={lognWithPhoneController} />
                        )}
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 flex inset-x-0 w-full max-w-[100vw]">
                <TileLineWrapper className="mt-auto" src={TileLine2Img} />
            </div>
        </>
    );
};

const Memoized = memo(LoginWithPhone);

const Styled = styled(Memoized)`
    .grecaptcha-badge {
        visibility: hidden;
    }
`;
export default Styled;

import { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { ReviewContext } from '../ReviewProduct';
import CustomCheckbox from './CustomCheckbox';
import TextArea from './TextArea';

const ReviewQuestionMultiResponse = styled(({ className }) => {
    const reviewController = useContext(ReviewContext);
    const [selectedOptions, setSelectedOptions] = useState<
        Record<string, boolean>
    >(
        (reviewController?.answerForCurrentQuestion?.answers as Record<
            string,
            boolean
        >) ?? {},
    );
    const [noAnswerCheckboxValue, setNoAnswerCheckboxValue] = useState(
        reviewController?.currentQuestion?.SampleFestProductQuestions_id
            ?.no_answer
            ? reviewController?.answerForCurrentQuestion?.noAnswer
            : false,
    );

    const [commentValue, setCommentValue] = useState(
        reviewController?.answerForCurrentQuestion?.comments ?? '',
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const key = event.target.value as string;
        setNoAnswerCheckboxValue(false);
        setSelectedOptions({
            ...selectedOptions,
            [key]: !selectedOptions[key],
        });
    };

    const handleCommentChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = event.target.value as string;
        setCommentValue(value);
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setNoAnswerCheckboxValue(event.target.checked);
        setSelectedOptions({});
    };

    useEffect(() => {
        const onSave = () => {
            reviewController?.saveQuestion({
                noAnswer: noAnswerCheckboxValue ? true : null,
                value: selectedOptions,
                comment: commentValue,
            });
            reviewController?.continueFlow();
        };
        reviewController?.mittEventEmitter?.on('save', onSave);

        return () => reviewController?.mittEventEmitter?.off('save', onSave);
    }, [
        reviewController,
        noAnswerCheckboxValue,
        commentValue,
        selectedOptions,
    ]);

    const options = reviewController?.currentQuestion
        ?.SampleFestProductQuestions_id?.multiresponse as string[];

    return (
        <div className={className}>
            <div className="flex flex-col justify-center items-start gap-2">
                {options?.map((item) => {
                    return (
                        <CustomCheckbox
                            key={item}
                            item={item}
                            handleChange={handleChange}
                            type="multiple"
                            selectedOptions={selectedOptions}
                        />
                    );
                })}

                {reviewController?.currentQuestion
                    ?.SampleFestProductQuestions_id?.no_answer ? (
                    <CustomCheckbox
                        item={
                            reviewController.currentQuestion
                                .SampleFestProductQuestions_id.no_answer_text ??
                            ''
                        }
                        handleChange={handleCheckboxChange}
                        type="single"
                        selectedOption={
                            noAnswerCheckboxValue
                                ? reviewController.currentQuestion
                                      .SampleFestProductQuestions_id
                                      .no_answer_text ?? ''
                                : ''
                        }
                    />
                ) : null}
                {reviewController?.currentQuestion
                    ?.SampleFestProductQuestions_id?.comments ? (
                    <TextArea
                        value={commentValue}
                        handleChange={handleCommentChange}
                        placeholder="Comments (optional)..."
                    />
                ) : null}
            </div>
        </div>
    );
})``;

export default ReviewQuestionMultiResponse;

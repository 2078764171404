import React from 'react';

interface ITextAreaProps {
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    disabled?: boolean;
    placeholder: string;
}

const TextArea = ({
    value,
    handleChange,
    disabled: noAnswerCheckboxValue,
    placeholder,
}: ITextAreaProps) => {
    return (
        <textarea
            className="w-full rounded-md border border-primary-400 text-dark placeholder-primary-300 min-h-20 p-2 focus:outline-primary-800 focus:shadow-xl"
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
            disabled={noAnswerCheckboxValue}
        />
    );
};

export default TextArea;

import { useEffect, useState } from 'react';

const useModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);
    const toggleModal = () => setIsOpen((prev) => !prev);

    return {
        isOpen,
        openModal,
        closeModal,
        toggleModal,
    };
};

export default useModal;

interface IUseISEdited {
    edit: boolean;
    success: boolean;
}

interface IUseISEditedReturn {
    value: IUseISEdited;
    editSuccess: () => void;
    editReset: () => void;
    setIsEdited: () => void;
}

export const useIsEdited = (time = 1000): IUseISEditedReturn => {
    const [value, setValue] = useState({ edit: false, success: false });

    const editSuccess = () => {
        setValue({ edit: false, success: true });
    };

    const editReset = () => {
        setValue({ edit: false, success: false });
    };

    const setIsEdited = () => {
        setValue({ edit: true, success: false });
    };
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (value.success) {
            timeout = setTimeout(() => {
                editReset();
            }, time);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [value, time]);

    return { value, editReset, editSuccess, setIsEdited };
};

export const useWindowWidth = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return width;
};

const INITIAL_TIMER = 30; // 30 seconds

export const useTimerCountdown = (
    time = INITIAL_TIMER,
): [number, () => void] => {
    const [timeLeft, setTimeLeft] = useState(time);

    const resetTimer = () => {
        setTimeLeft(time);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeLeft === 0) return;
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => {
            clearTimeout(timer);
        };
    }, [timeLeft]);
    return [timeLeft, resetTimer];
};

export function usePortalTarget(selector: string): HTMLElement | null {
    const [targetElement, setTargetElement] = useState<HTMLElement | null>(
        null,
    );

    useEffect(() => {
        const checkForTarget = () =>
            document.querySelector<HTMLElement>(selector);

        const element = checkForTarget();
        if (element) {
            setTargetElement(element);
            return;
        }

        const observer = new MutationObserver(() => {
            const element = checkForTarget();
            if (element) {
                setTargetElement(element);
                observer.disconnect();
            }
        });

        observer.observe(document.body, { childList: true, subtree: true });

        return () => observer.disconnect();
    }, [selector]);

    return targetElement;
}

import React, { memo } from 'react';
import styled from 'styled-components';

export interface IBoxProps {
    className?: string;
    children?: React.ReactNode;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const Box = ({ className, children, onClick, ...props }: IBoxProps) => {
    return (
        <div {...props} onClick={onClick} className={className}>
            {children}
        </div>
    );
};

const Memoized = memo(Box);
const Styled = styled(Memoized)`
    overflow: hidden;
    padding: 24px;
    border-radius: 8px;
    background-color: #e6eeed;
    color: #03594d;
`;
export default Styled;

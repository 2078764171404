import React from 'react';

const useProfileController = () => {
    const [editedField, setEditedField] = React.useState<string>('');
    const [error, setError] = React.useState<string>('');

    const openModal = (field: string) => {
        setEditedField(field);
    };

    const handleError = (error: string) => {
        setError(error);
    };

    const closeModal = () => {
        setEditedField('');
        setError('');
    };

    return {
        editedField,
        error,
        openModal,
        handleError,
        closeModal,
    };
};

export default useProfileController;

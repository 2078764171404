import { useLoginWithPhoneController } from '@clientweb/src/routes/auth/login/useLoginWithPhoneController';
import clsx from 'clsx';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

export interface IInputCodeProps {
    className?: string;
    codeLength: number;
    onSubmit?: (code: string, clearCode: () => void) => void;
    error?: string | null;
    onChange?: (code: string) => void;
    disabled?: boolean;
}

const InputOTP = ({
    className,
    codeLength,
    onSubmit,
    onChange,
    disabled,
    error,
}: IInputCodeProps) => {
    const [enteredCode, setEnteredCode] = useState<string[]>(
        new Array(codeLength).fill(''),
    );
    const [isFocused, setIsFocused] = useState(true);

    const inputRefs = useRef<HTMLInputElement[]>([]);

    const clearCode = () => {
        setEnteredCode(new Array(codeLength).fill(''));
        moveFocusToIndex(0);
    };

    const handleSubmit = (value: string) => {
        onSubmit && onSubmit(value, clearCode);
    };

    // Handle OTP input changes for individual boxes
    const handleOtpChange = (index: number, value: string) => {
        const newValue = (() => {
            if (value.length > 1) {
                return value.split('')[1] ?? '';
            }
            return value;
        })();
        if (!/^\d$/.test(newValue)) return;

        const newEnteredCode = [...enteredCode];
        newEnteredCode[index] = newValue;
        setEnteredCode(newEnteredCode);
        onChange && onChange(newEnteredCode.join(''));
        moveFocusToIndex(index + 1);
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        const pastedData = e.clipboardData.getData('Text').slice(0, codeLength);
        if (/^\d+$/.test(pastedData)) {
            const newEnteredCode = pastedData.split('');
            setEnteredCode(newEnteredCode);
            onChange && onChange(newEnteredCode.join(''));
        }
    };

    const moveFocusToIndex = (index: number) => {
        if (index < codeLength) {
            inputRefs.current[index]?.focus();
        }
    };

    useEffect(() => {
        if (enteredCode.every((digit) => digit !== '')) {
            handleSubmit(enteredCode.join(''));
        }
    }, [enteredCode]);

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    useEffect(() => {
        if (error) {
            console.log('error', error);
            clearCode();
        }
    }, [error]);

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number,
    ) => {
        if (e.key === 'Backspace' && index > 0) {
            const isInputRef = inputRefs.current[index];
            if (isInputRef && isInputRef.value === '') {
                inputRefs.current[index - 1]?.focus();
            }
            enteredCode[index] = '';
            if (isInputRef) isInputRef.value = '';
        } else if (e.key === 'Backspace' && index === 0) {
            enteredCode[0] = '';
            if (inputRefs.current[0]) inputRefs.current[0].value = '';
        } else if (e.key === 'ArrowLeft' && index > 0) {
            inputRefs.current[index - 1]?.focus();
        } else if (e.key === 'ArrowRight' && index < codeLength - 1) {
            inputRefs.current[index + 1]?.focus();
        }
    };

    return (
        <div className={clsx([`flex flex-col sm:gap-4`, className])}>
            <div className=" flex gap-2">
                {enteredCode.map((value, index) => {
                    return (
                        <input
                            disabled={disabled}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            className={`size-[50px] bg-white shadow-custom text-xl rounded-lg no-spinners border ${
                                error ? 'border-danger' : 'border-primary-800'
                            } ${
                                isFocused ? 'border-primary-500' : ''
                            } text-center focus:outline-none`}
                            autoComplete="one-time-code"
                            min={0}
                            max={9}
                            maxLength={1}
                            minLength={1}
                            key={index}
                            value={value}
                            ref={(el) => {
                                if (el) inputRefs.current[index] = el;
                            }}
                            onChange={(e) =>
                                handleOtpChange(index, e.target.value)
                            }
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onPaste={handlePaste}
                            type="number"
                        />
                    );
                })}
            </div>
            {error && (
                <div className="help is-danger mt-2 font-outfit">
                    This is an error code
                </div>
            )}
        </div>
    );
};

export const Styled = styled(InputOTP)`
    /* For Chrome, Safari, Edge, and Opera */
    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* For Firefox */
    input[type='number'] {
        -moz-appearance: textfield;
    }

    ${({ disabled }) =>
        disabled
            ? css`
                  opacity: 0.3;
              `
            : ``}
`;

export default Styled;

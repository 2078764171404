import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ReviewContext } from '../ReviewProduct';
import CustomCheckbox from './CustomCheckbox';
import TextArea from './TextArea';

const ReviewQuestionSingleResponse = styled(() => {
    const reviewController = useContext(ReviewContext);
    const [selectedOption, setSelectedOption] = useState<string | null>(
        (reviewController?.answerForCurrentQuestion?.answers as string) ?? null,
    );
    const [noAnswerCheckboxValue, setNoAnswerCheckboxValue] = useState(
        reviewController?.currentQuestion?.SampleFestProductQuestions_id
            ?.no_answer
            ? reviewController?.answerForCurrentQuestion?.noAnswer
            : false,
    );
    const [commentValue, setCommentValue] = useState(
        reviewController?.answerForCurrentQuestion?.comments ?? '',
    );

    const handleCommentChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = event.target.value as string;
        setCommentValue(value);
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setNoAnswerCheckboxValue(event.target.checked);
        setSelectedOption(null);
    };

    const options = reviewController?.currentQuestion
        ?.SampleFestProductQuestions_id?.single_response as string[];

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(event.target.value);
        setNoAnswerCheckboxValue(false);
    };

    useEffect(() => {
        const onSave = () => {
            reviewController?.saveQuestion({
                noAnswer: noAnswerCheckboxValue ? true : null,
                value: selectedOption,
                comment: commentValue,
            });
            reviewController?.continueFlow();
        };
        reviewController?.mittEventEmitter?.on('save', onSave);

        return () => reviewController?.mittEventEmitter?.off('save', onSave);
    }, [reviewController, commentValue, selectedOption, noAnswerCheckboxValue]);

    return (
        <div className="flex flex-col justify-center gap-2">
            {options?.map((item) => {
                return (
                    <CustomCheckbox
                        key={item}
                        item={item}
                        type="single"
                        isRadio
                        selectedOption={selectedOption ?? ''}
                        handleChange={handleChange}
                    />
                );
            })}

            {reviewController?.currentQuestion?.SampleFestProductQuestions_id
                ?.no_answer ? (
                <CustomCheckbox
                    type="single"
                    item={
                        reviewController?.currentQuestion
                            ?.SampleFestProductQuestions_id?.no_answer_text ??
                        ''
                    }
                    handleChange={handleCheckboxChange}
                    selectedOption={
                        noAnswerCheckboxValue
                            ? reviewController?.currentQuestion
                                  ?.SampleFestProductQuestions_id
                                  ?.no_answer_text ?? ''
                            : ''
                    }
                />
            ) : null}
            {reviewController?.currentQuestion?.SampleFestProductQuestions_id
                ?.comments ? (
                <TextArea
                    placeholder="Comments (optional)..."
                    handleChange={handleCommentChange}
                    value={commentValue}
                />
            ) : null}
        </div>
    );
})``;

export default ReviewQuestionSingleResponse;

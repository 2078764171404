import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
        --bulma-family-primary: "Roboto","ui-monospace","Inter var", sans-serif;
    }

    .font-bold {
        font-family: "Outfit", sans-serif !important;
    }

    .notistack-SnackbarContainer {
        position: absolute;
    }
    #notistack-snackbar {
        svg {
            padding-right: 0.5rem;
        }
    }

    #toast-root {
        top: -185px;
        height: 200px;
        width: 100%;
        pointer-events: none;
    }
`;

export default GlobalStyle;

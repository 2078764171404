import { REDIRECT_AFTER_NOT_AUTHORIZED } from '@clientweb/src/routes/Router';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const ProtectedRoute = () => {
    const auth = getAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                navigate(REDIRECT_AFTER_NOT_AUTHORIZED);
            }
        });

        return () => unsubscribe();
    }, []);

    return <Outlet />;
};

export default ProtectedRoute;

import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import Spinner from '@clientweb/src/assets/progress-spinner-primary.svg';
import SamplefestLogo from '@clientweb/src/design/v1/general/SamplefestLogo/SamplefestLogo';
import {
    TileLine1Img,
    TileLine2Img,
    TileLineWrapper,
} from '@clientweb/src/design/v1/misc/TileLines/TileLine';
import { memo, useContext } from 'react';
import styled from 'styled-components';

const FullScreenLoader = () => {
    const appRootController = useContext(AppRootContext);

    return (
        <div className="absolute flex flex-col inset-0 overflow-hidden bg-teritary-bg z-[300]">
            <TileLineWrapper src={TileLine1Img} />
            <section className="mt-auto mb-auto flex flex-col justify-center items-center gap-1">
                <div className="flex flex-col items-center gap-2">
                    <img
                        src={appRootController?.whiteLabelLogoUrl}
                        alt="badge"
                        className="size-[144px]"
                    />

                    <div className="flex flex-col gap-2">
                        <p className="text-xl text-primary-800 text-center font-outfit">
                            In association with
                        </p>
                        <SamplefestLogo
                            classNameRetailer="h-14"
                            withoutOrganization
                        />
                    </div>
                </div>
                <img
                    src={Spinner}
                    alt="spinner"
                    className="size-7 animate-spin mt-2"
                />
            </section>
            <TileLineWrapper className="mt-auto" src={TileLine2Img} />
        </div>
    );
};

const Memoized = memo(FullScreenLoader);
const Styled = styled(Memoized)``;
export default Styled;

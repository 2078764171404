import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import { useContext } from 'react';
import styled from 'styled-components';

const CampaignLevelQuestionBanner = styled(
    ({
        className,
        onConfirm,
    }: {
        className?: string;
        onConfirm: () => void;
    }) => {
        const appRootController = useContext(AppRootContext);
        return (
            <div onClick={() => onConfirm()} className={className}>
                <img
                    src={appRootController?.whiteLabelLogoUrl}
                    className="size-10 xxs:size-14 z-20"
                    alt="retailer-logo"
                />
                <div className="flex flex-col mt-1">
                    <span className="text-primary-800 text-base font-bold font-outfit">
                        We would really appreciate your help!
                    </span>
                    <span>This will take less than a minute</span>
                </div>
            </div>
        );
    },
)`
    padding: 1rem;
    background-color: white;
    width: 100vw;
    z-index: 99999;
    display: flex;
    gap: 1rem;
`;

export default CampaignLevelQuestionBanner;

import { trpcReact } from '@clientweb/src/AppRoot/trpcClient.react';
import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import InputOTP from '@clientweb/src/design/v1/dataEntry/InputOTP/InputOTP';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { memo, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import CrossSVG from '../../assets/bottomNav/cross-no-border.svg?react';
import CameraPermission from '../../assets/permission_camera.svg';
import QRCorner from '../../assets/qr-corner.svg';

export interface IScanProps {
    className?: string;
}

const Scan = styled(({ className }: IScanProps) => {
    const [permission, setPermission] = useState('denied');
    const productListQuery =
        trpcReact.productRouter.v1.getListForUser.useQuery();

    const onManualEntrySuccess = () => {
        if (productListQuery.isFetching) return;
        productListQuery.refetch();
        navigate('/transition/scan-success');
    };
    const [data, setData] = useState<IDetectedBarcode[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            if (!data?.length) return;
            const [scannedQRData] = data;
            if (!scannedQRData) return;
            const { rawValue } = scannedQRData;
            const parsedUrl = new URL(rawValue);
            const campaignShortCode = parsedUrl.searchParams.get('campaign');
            if (!campaignShortCode)
                throw new Error('Missing campaign short code');
            await trpcVanilla.campaignRouter.v1.assignCampaignToUser.mutate({
                campaignShortCode,
            });
            navigate('/transition/scan-success');
        })();
    }, [data, navigate]);

    useEffect(() => {
        const checkPermission = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true,
                });
                if (stream) {
                    setPermission('granted');
                    // Stop the stream to release the camera
                    stream.getTracks().forEach((track) => track.stop());
                }
            } catch (err) {
                const error = err as DOMException;
                if (
                    error.name === 'NotAllowedError' ||
                    error.name === 'PermissionDeniedError'
                ) {
                    setPermission('denied');
                } else {
                    console.error('Error accessing camera:', err);
                    setPermission('error');
                }
            }
        };
        checkPermission();
    }, []);

    return (
        <div
            className={`${className} absolute inset-0 z-[105] bg-primary overflow-auto px-4 py-6 flex flex-col gap-4`}
        >
            <Link
                to="/products"
                className="absolute top-3 left-3 text-white cursor-pointer"
            >
                <CrossSVG />
            </Link>
            <div className="scanner-container">
                {permission !== 'granted' ? (
                    <img
                        src={CameraPermission}
                        alt="camera permission"
                        className="mx-auto h-3/4 w-3/4 mt-10"
                    />
                ) : (
                    <Scanner
                        onScan={(result) => setData(result)}
                        classNames={{ container: 'scanner-svg' }}
                        components={{ finder: false }}
                        children={
                            <>
                                <img
                                    src={QRCorner}
                                    alt="top left qr corner"
                                    className="absolute top-2 left-2"
                                />
                                <img
                                    src={QRCorner}
                                    alt="top right qr corner"
                                    className="absolute top-2 right-2 rotate-90"
                                />
                                <img
                                    src={QRCorner}
                                    alt="bottom left qr corner"
                                    className="absolute bottom-2 left-2 -rotate-90"
                                />
                                <img
                                    src={QRCorner}
                                    alt="bottom right qr corner"
                                    className="absolute bottom-2 right-2 -rotate-180"
                                />
                            </>
                        }
                    />
                )}
            </div>
            <div className="text-white text-center max-w-96 mx-auto">
                <h3 className="font-bold text-[32px] leading-[40px]">
                    {permission !== 'granted'
                        ? 'Allow Camera Permission'
                        : 'Scan QR code'}
                </h3>
                <p className="font-[400] text-base">
                    {permission !== 'granted'
                        ? 'Samplefest needs permission to access camera to scan QR code'
                        : 'This is text about how to find the QR code on the sample boxand any other detail'}
                </p>
            </div>
            <ManualEntryCode onSuccess={onManualEntrySuccess} />
        </div>
    );
})`
    .scanner-container,
    .scanner-svg {
        width: 272px;
        height: 272px;

        @media (max-width: 400px) {
            width: 230px;
            height: 230px;
        }
    }

    .scanner-container {
        overflow: hidden;
        border-radius: 12px;
        position: relative;
        margin-inline: auto;
    }

    .scanner-container video,
    .scanner-container canvas {
        border-radius: 12px;
    }

    .scanner-svg svg {
        border: 25px solid rgba(0, 0, 0, 0) !important;
    }

    .scanner-svg svg path {
        stroke: #c7ec33;
    }
`;

const ManualEntryCode = styled(({ className, onSuccess, onError }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const assignCampaignToUserMutation =
        trpcReact.campaignRouter.v1.assignCampaignToUser.useMutation();

    const onCodeDone = async (code: string, clearCode: () => void) => {
        setIsSubmitting(true);
        try {
            if (assignCampaignToUserMutation.isLoading) return;
            await assignCampaignToUserMutation.mutateAsync({
                campaignShortCode: code,
            });
            onSuccess && onSuccess();
            clearCode();
        } catch (error) {
            clearCode();
            onError && onError();
        }
        setIsSubmitting(false);
    };

    return (
        <div className="flex flex-col text-xl text-center font-bold mt-auto">
            <p className=" text-white">Facing issues?</p>
            <p
                className=" text-secondary-bright font-outfit cursor-pointer"
                onClick={() => setIsOpen(true)}
            >
                Enter the 6 digit code instead
            </p>

            {isOpen && (
                <div className="absolute bg-white inset-x-0 h-[40%] bottom-0 z-[110] flex flex-col gap-3 p-3 rounded-t-md">
                    <div className="flex">
                        <button onClick={() => setIsOpen(false)}>
                            <CrossSVG />
                        </button>
                        <p className="text-center w-full">Add box</p>
                    </div>
                    <p>
                        This is text about how to find the 6 digit code on the
                        sample box and enter it here
                    </p>
                    <div className="flex flex-col gap-1">
                        <p className="text-sm text-primary text-start">
                            Enter the code on the sample box
                        </p>
                        <InputOTP
                            disabled={assignCampaignToUserMutation.isLoading}
                            codeLength={6}
                            onSubmit={onCodeDone}
                        />
                    </div>
                    <Button disabled={isSubmitting}>ADD BOX</Button>
                </div>
            )}
        </div>
    );
})``;

const Memoized = memo(Scan);
const Styled = styled(Memoized)``;
export default Styled;

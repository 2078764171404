import RetailerLogo from '@clientweb/src/assets/retailer-logo.svg';
import { directusFileUrl, timeout } from '@clientweb/src/utils/frontend';
import { LOCAL_STORAGE_KEYS } from '@clientweb/src/utils/localstorage';
import { QueryClient } from '@tanstack/react-query';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

import { trpcReact } from './trpcClient.react';
import { trpcVanilla } from './trpcClient.vanilla';

export const DEFAULT_TRANSITION_TIMEOUT = 2000;

// An enum with all the types of actions to use in our reducer
export enum AppRootReducerActionKind {
    SET_GLOBAL_IS_LOADING = 'SET_GLOBAL_IS_LOADING',
}

// An interface for our actions
interface AppRootReducerAction {
    type: AppRootReducerActionKind;
    payload: unknown;
}

export const AppRootContext = createContext<ReturnType<
    typeof useAppRoot
> | null>(null);

// An interface for our state
interface AppRootReducerState {
    isLoading: boolean;
}

// Our reducer function that uses a switch statement to handle our actions
function reducer(state: AppRootReducerState, action: AppRootReducerAction) {
    const { type, payload } = action;

    switch (type) {
        case AppRootReducerActionKind.SET_GLOBAL_IS_LOADING:
            return {
                ...state,
                isLoading: payload as AppRootReducerState['isLoading'],
            };
        default:
            return state;
    }
}

const initialState = {
    isLoading: true,
};

export function makeQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                // With SSR, we usually want to set some default staleTime
                // above 0 to avoid refetching immediately on the client
                // staleTime: 60 * 1000,
                refetchOnMount: true,
                refetchOnWindowFocus: false,
                refetchOnReconnect: true,
            },
        },
    });
}

let clientQueryClient: QueryClient | undefined = undefined;

function getQueryClient() {
    if (typeof window === 'undefined') {
        // Server: always make a new query client
        return makeQueryClient();
    } else {
        // Browser: make a new query client if we don't already have one
        if (!clientQueryClient) clientQueryClient = makeQueryClient();
        return clientQueryClient;
    }
}
export const useAppRoot = () => {
    const auth = getAuth();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(reducer, initialState);
    const queryClient = getQueryClient();
    const identifiedOrganization =
        window.location.host?.split('.')?.[0] ?? 'missing';
    const isInAuthPages =
        window.location.pathname.startsWith('/auth') ||
        window.location.pathname.startsWith('/entry');

    const getUserProfileQuery = trpcReact.userRouter.v1.get.useQuery(
        undefined,
        {
            enabled: false,
        },
    );
    const getOrganizationWhitelabelConfigQuery =
        trpcReact.organizationRouter.v1.getWhitelabelConfigForOrganization.useQuery(
            {
                companyName: identifiedOrganization,
            },
            {
                enabled: false,
                staleTime: 1000 * 60 * 5, // 5 minutes, adjust as needed
                cacheTime: 1000 * 60 * 10, // cache persists for 10 minutes
                refetchOnWindowFocus: true, // refetch when window is focused
                refetchInterval: 1000 * 60 * 1, // or every 1 minute in the background
            },
        );
    const getNumberOfProductsLeftForReviewQuery =
        trpcReact.productRouter.v1.getNumberOfProductsLeftForReview.useQuery(
            undefined,
            {
                enabled: !state.isLoading && !isInAuthPages,
                refetchOnWindowFocus: false,
            },
        );

    const whiteLabelLogoUrl =
        directusFileUrl(getOrganizationWhitelabelConfigQuery.data?.logo) ??
        RetailerLogo;

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            await getUserProfileQuery.refetch();
            await getOrganizationWhitelabelConfigQuery.refetch();
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        (async () => {
            (async () => {
                if (!auth.currentUser?.phoneNumber) return;
                const qrCodeCampaignShortCode = localStorage.getItem(
                    LOCAL_STORAGE_KEYS.SCANNED_QR_CODE_CAMPAIGN_SHORT_CODE,
                );

                if (qrCodeCampaignShortCode?.length) {
                    localStorage.removeItem(
                        LOCAL_STORAGE_KEYS.SCANNED_QR_CODE_CAMPAIGN_SHORT_CODE,
                    );
                    await trpcVanilla.campaignRouter.v1.assignCampaignToUser.mutate(
                        {
                            campaignShortCode: qrCodeCampaignShortCode,
                        },
                    );
                }
            })();
            /**
             * Edge case for auth pages
             * Enforce 2 seconds on loading screen
             */
            if (isInAuthPages) {
                await timeout(2000);
                dispatch({
                    type: AppRootReducerActionKind.SET_GLOBAL_IS_LOADING,
                    payload: false,
                });
                return;
            }

            if (getUserProfileQuery.status !== 'success') return;
            if (getUserProfileQuery.fetchStatus === 'fetching') return;
            if (!getOrganizationWhitelabelConfigQuery.data) return;
            const userData = getUserProfileQuery.data;

            if (!userData) {
                return navigate('/auth/logout');
            }
            await timeout(2000);

            dispatch({
                type: AppRootReducerActionKind.SET_GLOBAL_IS_LOADING,
                payload: false,
            });
        })();
    }, [
        getUserProfileQuery.data,
        getOrganizationWhitelabelConfigQuery.data,
        getUserProfileQuery.isFetching,
        navigate,
        getUserProfileQuery.status,
        getUserProfileQuery.fetchStatus,
        isInAuthPages,
    ]);

    return {
        identifiedOrganization,
        whiteLabelLogoUrl,
        getNumberOfProductsLeftForReviewQuery,
        getOrganizationWhitelabelConfigQuery,
        getUserProfileQuery,
        state,
        dispatch,
        queryClient,
    };
};

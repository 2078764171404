import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import InfoIcon from '@clientweb/src/assets/icons/info.svg';
import FullscreenLoader from '@clientweb/src/components/FullscreenLoader/FullscreenLoader';
import QuestionnaireBottomNav from '@clientweb/src/routes/Questionnaire/ReviewProduct/QuestionnaireBottomNav';
import ReviewQuestion from '@clientweb/src/routes/Questionnaire/ReviewProduct/Questions/ReviewQuestion';
import { directusFileUrl } from '@clientweb/src/utils/frontend';
import { memo, useContext } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import CrossIconSVG from '../../../assets/bottomNav/cross-no-border.svg?react';
import { ReviewContext } from './ReviewProduct';

export interface IReviewStepProps {
    className?: string;
}

const ReviewStep = ({ className }: IReviewStepProps) => {
    const reviewController = useContext(ReviewContext);

    if (!reviewController?.isDataFetched) return <FullscreenLoader />;

    return (
        <div className={className}>
            <div className="flex justify-between items-center py-3 px-5 border-b border-shade-100">
                {reviewController?.productId ? (
                    <ProductHeader />
                ) : (
                    <CampaignHeader />
                )}
            </div>
            <ReviewQuestion className="mt-4 px-5" />
            <QuestionnaireBottomNav />
        </div>
    );
};

const ProductHeader = styled(({ className }) => {
    const reviewController = useContext(ReviewContext);

    return (
        <div className={className}>
            <div className="flex items-center gap-3">
                <Link to="/products" className="size-[14px]">
                    <CrossIconSVG />
                </Link>
                <img
                    src={
                        directusFileUrl(reviewController?.thumbnailUrl, {
                            height: '48',
                            width: '72',
                        }) ?? ''
                    }
                    width={72}
                    height={48}
                    alt="thumbnail"
                    className="rounded-md"
                />
                <span className="font-bold text-xl text-dark">
                    {reviewController?.getProductQuery?.data?.long_name}
                </span>
            </div>
            <img
                src={InfoIcon}
                alt="info icon"
                onClick={() => {
                    reviewController?.productDetailsSection.openModal();
                }}
            />
        </div>
    );
})`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

const CampaignHeader = styled(({ className }) => {
    const appRootController = useContext(AppRootContext);

    return (
        <div className={className}>
            <div className="flex items-center gap-3">
                <img
                    src={appRootController?.whiteLabelLogoUrl}
                    className="size-10 xxs:size-14 -mr-2 z-20"
                    alt="retailer-logo"
                />
                <Link to="/products">
                    <p className="text-primary-800 font-medium font-outfit absolute top-[20px] right-[20px]">
                        Skip
                    </p>
                </Link>
            </div>
        </div>
    );
})``;

const Memoized = memo(ReviewStep);
const Styled = styled(Memoized)`
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
`;
export default Styled;

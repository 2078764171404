import ProgressBar from '@clientweb/src/design/v1/ProgressBar/ProgressBar';
import { ReviewContext } from '@clientweb/src/routes/Questionnaire/ReviewProduct/ReviewProduct';
import { useContext } from 'react';

const QuestionnaireBottomNav = () => {
    const reviewController = useContext(ReviewContext);
    const currentStep = (reviewController?.currentQuestionIndex ?? 0) + 1;
    const totalSteps = reviewController?.questions?.length ?? 0;

    return (
        <div className="sticky bottom-0 w-full bg-white z-[100]">
            <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
            <div className="flex justify-center items-center px-8 py-3">
                <button
                    onClick={() =>
                        reviewController?.mittEventEmitter.emit('save')
                    }
                    className="uppercase text-2xl button bg-[#03594D] text-secondary-bright font-bold is-fullwidth rounded-[8px] py-4"
                >
                    {reviewController?.isLastQuestion ? 'Finish' : 'Next'}
                </button>
            </div>
        </div>
    );
};

export default QuestionnaireBottomNav;

import { firebaseClientAppAuth } from '@clientweb/src/firebase.client';
import { createTRPCProxyClient, httpBatchLink } from '@trpc/client';
import normalizeUrl from 'normalize-url';
import superjson from 'superjson';

import type { AppRouter } from '@backend/trpc/trpcRouter';

import { backendConfig } from './config';

export const TRPC_URL = normalizeUrl(`${backendConfig}/trpc`);

export const trpcVanilla = createTRPCProxyClient<AppRouter>({
    transformer: superjson,
    links: [
        httpBatchLink({
            url: TRPC_URL,
            headers: async () => {
                const firebaseToken =
                    await firebaseClientAppAuth?.currentUser?.getIdToken();

                return { authorization: `Bearer ${firebaseToken}` };
            },
        }),
    ],
});

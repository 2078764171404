import { TRPC_URL, trpcReact } from '@clientweb/src/AppRoot/trpcClient.react';
import { makeQueryClient } from '@clientweb/src/AppRoot/useAppRootController';
import { firebaseClientAppAuth } from '@clientweb/src/firebase.client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
// import { QueryClientProvider } from '@tanstack/react-query';
import { httpBatchLink } from '@trpc/client';
import React, { useEffect, useState } from 'react';
import superjson from 'superjson';

export interface ITRPCProviderProps {
    className?: string;
    children?: React.ReactNode | React.ReactNode[];
}

const TRPCProvider = ({ children }: ITRPCProviderProps) => {
    const [queryClient] = useState(() => makeQueryClient());

    const trpcClient = trpcReact.createClient({
        transformer: superjson,
        links: [
            httpBatchLink({
                url: TRPC_URL,
                headers: async () => {
                    const firebaseToken =
                        await firebaseClientAppAuth?.currentUser?.getIdToken();

                    return { authorization: `Bearer ${firebaseToken}` };
                },
            }),
        ],
    });

    const localStoragePersister = createSyncStoragePersister({
        storage: window.localStorage,
    });

    const [isCacheReady, setIsCacheReady] = useState(false);

    useEffect(() => {
        const cache = queryClient.getQueryCache();
        /**
         * Fallback, in case of problem
         */
        setTimeout(() => {
            setIsCacheReady(true);
        }, 100);
    }, []);

    return (
        <trpcReact.Provider client={trpcClient} queryClient={queryClient}>
            <PersistQueryClientProvider
                client={queryClient}
                persistOptions={{ persister: localStoragePersister }}
            >
                {isCacheReady ? children : null}
            </PersistQueryClientProvider>
        </trpcReact.Provider>
    );
};

export default TRPCProvider;

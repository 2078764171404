export enum PRODUCT_STATUS {
    NEW_PRODUCT = 'new',
    REVIEW_IN_PROGRESS = 'in_progres',
    REVIEW_DONE = 'completed',
    ERROR = 'error',
}

export type QuestionarieAnswers = Record<string, QuestionarieAnswer>;

export interface QuestionarieAnswer {
    answers: unknown;
    comments?: string | null;
    updated: number;
    noAnswer: true | null;
}

import FullscreenLoader from '@clientweb/src/components/FullscreenLoader/FullscreenLoader';
import { LOCAL_STORAGE_KEYS } from '@clientweb/src/utils/localstorage';
import * as Sentry from '@sentry/react';
import { getAuth } from 'firebase/auth';
import { memo, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { trpcVanilla } from '../AppRoot/trpcClient.vanilla';
import { timeout } from '../utils/frontend';

const EntryPointForQRCode = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const auth = getAuth();

    useEffect(() => {
        (async () => {
            const isUserLoggedIn =
                (auth?.currentUser?.phoneNumber?.length ?? 0) > 0;
            const campaignShortCode = searchParams.get('campaign');

            if (isUserLoggedIn) {
                try {
                    if (campaignShortCode?.length) {
                        await trpcVanilla.campaignRouter.v1.assignCampaignToUser.mutate(
                            {
                                campaignShortCode: campaignShortCode,
                            },
                        );
                    }
                } catch (error) {
                    Sentry.captureException(error);
                }
                return navigate('/products');
            }

            if (!campaignShortCode) {
                return navigate('/auth/get-started');
            }
            localStorage.setItem(
                LOCAL_STORAGE_KEYS.SCANNED_QR_CODE_CAMPAIGN_SHORT_CODE,
                campaignShortCode,
            );
            await timeout();
            return navigate('/auth/login');
        })();
    }, [navigate, searchParams]);

    return <FullscreenLoader />;
};

const Memoized = memo(EntryPointForQRCode);
const Styled = styled(Memoized)``;
export default Styled;

export const backendConfig = (() => {
    try {
        const parsedJSON = JSON.parse(
            import.meta.env['VITE_BACKEND_API_URL_CONFIG'],
        );
        const env = import.meta.env['VITE_SAMPLE_FEST_ENVIRONMENT'];
        if (env === 'development') {
            return parsedJSON[env];
        }
        const organization = window.location.host?.split('.')?.[0] ?? 'missing';
        const config = parsedJSON[env][organization];
        if (!config)
            throw new Error(
                `Missing config for ${env} ${organization}, received ${JSON.stringify(
                    parsedJSON,
                )}`,
            );
        return config;
    } catch (error) {
        console.error(
            `Error while parsing VITE_BACKEND_API_URL_CONFIG: ${error}`,
        );
    }
})();

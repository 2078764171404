import { useState } from 'react';

import Chip from './Chip';

const tabs = ['Description', 'Nutritional Info'];

const Tabs = ({ description }: { description: string }) => {
    const [selected, setSelected] = useState(tabs[0]);

    return (
        <div className="flex flex-col gap-5">
            <div className="px-4 border-b border-shade-200 flex items-center flex-wrap gap-4">
                {tabs.map((tab) => (
                    <Chip
                        text={tab}
                        selected={selected === tab}
                        setSelected={setSelected}
                        key={tab}
                    />
                ))}
            </div>
            <div>
                {selected === 'Description' && (
                    <div
                        className="p-4 overflow-scroll text-dark"
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                )}
                {selected === 'Nutritional Info' && (
                    <p className="p-4 text-dark">
                        {'Nutritional Info will be displayed here'}
                    </p>
                )}
            </div>
        </div>
    );
};

export default Tabs;

import Next from '@clientweb/src/assets/Next.svg';
import Previous from '@clientweb/src/assets/Previous.svg';
import { format, getMonth, getYear } from 'date-fns';
import { setDefaultOptions } from 'date-fns';
import { enUS } from 'date-fns/locale';
import React, { memo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';

import CrossIcon from '../../assets/bottomNav/cross-no-border.svg?react';

interface IProps {
    onChange: (date: Date | null) => void;
    value: Date;
    className?: string;
}

setDefaultOptions({
    locale: {
        ...enUS,
        options: {
            ...enUS.options,
            weekStartsOn: 0,
            firstWeekContainsDate: 1,
        },
        localize: {
            ...enUS.localize,
            day: (n) =>
                ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'][n] ?? '',
        },
    },
});

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const CustomDatePicker = ({ onChange, value, className }: IProps) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className={className}>
            <DatePicker
                fixedHeight
                inline
                shouldCloseOnSelect={false}
                dateFormat="dd/mm/yyyy"
                maxDate={new Date()}
                selected={value}
                onChange={(date) => {
                    setIsOpen(false);
                    onChange(date as Date);
                }}
                renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                }) => (
                    <div className="flex justify-between px-4 py-2 w-full mb-3">
                        <div className="flex items-center gap-1">
                            <div className="flex gap-1">
                                <p className="text-[17px] text-black font-[590] tracking-[-0.5px] leading-[22px]">
                                    {format(date, 'MMMM')}
                                </p>
                                <p className="text-[17px] text-black font-[590] tracking-[-0.5px] leading-[22px]">
                                    {getYear(date)}
                                </p>
                            </div>
                            <button
                                type="button"
                                className="relative w-5 h-[16px]"
                                onClick={() => setIsOpen(true)}
                            >
                                <img
                                    src={Next}
                                    alt="next"
                                    className="object-contain absolute inset-0 w-full h-full"
                                />
                            </button>
                            {isOpen && (
                                <div className="absolute top-0 left-0 w-full h-full rounded-lg">
                                    <button
                                        className="absolute top-2 right-2  z-[200]"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {React.cloneElement(<CrossIcon />, {
                                            className: 'size-3',
                                        })}
                                    </button>
                                    <DatePicker
                                        selected={date}
                                        onChange={(date) => {
                                            if (!date) return;
                                            changeYear(getYear(date));
                                            setIsOpen(false);
                                        }}
                                        maxDate={new Date()}
                                        showYearPicker
                                        inline
                                        showMonthDropdown
                                        calendarClassName="year-picker"
                                        dateFormat="yyyy"
                                    >
                                        <select
                                            value={months[getMonth(date)]}
                                            onChange={({ target: { value } }) =>
                                                changeMonth(
                                                    months.indexOf(value),
                                                )
                                            }
                                            className="mx-auto text-base my-2"
                                        >
                                            {months.map((option) => (
                                                <option
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </DatePicker>
                                </div>
                            )}
                        </div>
                        <div className="flex items-center text-2xl gap-4">
                            <button
                                type="button"
                                onClick={decreaseMonth}
                                disabled={prevMonthButtonDisabled}
                            >
                                <img
                                    src={Previous}
                                    alt="next"
                                    width={15}
                                    height={24}
                                />
                            </button>
                            <button
                                type="button"
                                onClick={increaseMonth}
                                disabled={nextMonthButtonDisabled}
                            >
                                <img
                                    src={Next}
                                    alt="next"
                                    width={15}
                                    height={24}
                                />
                            </button>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};

const Memoized = memo(CustomDatePicker);
const Styled = styled(Memoized)`
    * {
        float: unset;
        background-color: white;
        border: none;
    }

    .year-picker {
        width: 100%;
        border: 1px solid #03594d;
        border-radius: 8px;
        padding: 4px;

        * {
            border-radius: 8px;
        }

        .react-datepicker__year-wrapper {
            justify-content: center;
            font-size: 16px;
            max-width: 100% !important;

            .react-datepicker__year-text--selected {
                font-weight: 510;
                color: rgba(0, 122, 255, 1);
            }
        }

        .react-datepicker__children-container {
            margin-inline: auto;
        }
    }

    .react-datepicker__day-names {
        display: flex;
        justify-content: space-evenly;
        .react-datepicker__day-name {
            font-size: 13px;
            line-height: 18px;
            color: rgba(60, 60, 67, 0.3);
        }
    }

    .react-datepicker__week {
        display: flex;
        justify-content: space-evenly;

        .react-datepicker__day {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            width: 44px;
            height: 44px;
            font-weight: 400;
            color: black;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: -0.45px;

            &:hover {
                color: rgba(0, 122, 255, 1);
                background-color: white;
            }
        }

        .react-datepicker__day--selected {
            font-size: 24px;
            font-weight: 510;
            color: rgba(0, 122, 255, 1);
            background-color: rgba(0, 122, 255, 0.12);
        }

        .react-datepicker__day--disabled {
            pointer-events: none;
            color: rgba(0, 0, 0, 0.6);
        }
    }
`;
export default Styled;

import Spinner from '@clientweb/src/assets/progress-spinner.svg';
import { cn } from '@clientweb/src/utils/frontend';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva('', {
    variants: {
        variant: {
            primary: 'button bg-primary text-secondary-bright font-bold',
            primaryV2: 'button bg-primary text-white font-bold',
            secondary:
                'button text-primary-800 font-[600] text-2xl bg-[#E6EEED] border-none',
            secondaryV2:
                'button text-primary-800 font-[600] bg-secondary-bright border-none',
            delete: 'delete',
            navLink: 'flex flex-col items-center justify-center gap-1',
        },
        size: {
            default: 'w-full text-2xl py-2',
            icon: 'size-6',
        },
        rounded: {
            default: 'rounded-lg',
            full: 'rounded-full',
        },
    },
    defaultVariants: {
        variant: 'primary',
        size: 'default',
        rounded: 'default',
    },
});

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement>,
        VariantProps<typeof buttonVariants> {
    isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        { className, variant, size, rounded, children, isLoading, ...props },
        ref,
    ) => {
        const isDelete = variant === 'delete';
        return (
            <button
                className={cn(
                    'is-outfit tracking-wide disabled:opacity-60 disabled:cursor-not-allowed disabled:bg-primary-800 disabled:text-secondary',
                    buttonVariants({
                        variant,
                        size: isDelete ? null : size,
                        rounded: isDelete ? null : rounded,
                        className,
                    }),
                )}
                ref={ref}
                disabled={props.disabled || isLoading}
                {...props}
            >
                {isLoading ? (
                    <img
                        src={Spinner}
                        className="animate-spin size-6"
                        alt="spinner"
                    />
                ) : (
                    children
                )}
            </button>
        );
    },
);

export { Button, buttonVariants };

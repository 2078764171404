import { motion as m } from 'framer-motion';
import { Dispatch, SetStateAction } from 'react';

interface IChipProps {
    text: string;
    selected: boolean;
    setSelected: Dispatch<SetStateAction<string | undefined>>;
}

const Chip = ({ text, selected, setSelected }: IChipProps) => {
    return (
        <button
            onClick={() => setSelected(text)}
            className={`${
                !selected ? 'text-dark' : 'text-primary-800'
            } text-base transition-colors p-2.5 relative`}
        >
            <span className="relative z-10">{text}</span>
            {selected && (
                <m.span
                    layoutId="pill-tab"
                    transition={{ type: 'spring', duration: 0.5 }}
                    className="absolute inset-x-0 top-0 -bottom-[1px] z-0 border-b border-primary-800"
                ></m.span>
            )}
        </button>
    );
};

export default Chip;

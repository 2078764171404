import { Create_Directus_Files_Input } from '@directus/gql/graphql';
import { clsx, type ClassValue } from 'clsx';
import normalizeUrl from 'normalize-url';
import { twMerge } from 'tailwind-merge';

export const imageURLToBase64 = (
    src: string,
    outputFormat: 'image/png' | 'image/jpeg' | 'image/webp' = 'image/png',
): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';

        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (!ctx) throw new Error();
            canvas.height = img.naturalHeight;
            canvas.width = img.naturalWidth;
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL(outputFormat);
            resolve(dataURL);
        };

        img.onerror = (error) => reject(error);
        img.src = src;
    });
};

export const waitTimeout = (timeout = 500) =>
    new Promise((resolve) => {
        setTimeout(() => {
            resolve(void 0);
        }, timeout);
    });

const DIRECTUS_URL = import.meta.env['VITE_DIRECTUS_API_URL'];
const DIRECTUS_TOKEN = import.meta.env['VITE_DIRECTUS_TOKEN'];

if (!DIRECTUS_URL) {
    throw new Error('No directus API url given');
}

if (!DIRECTUS_TOKEN) {
    throw new Error('No directus API access token given');
}

export function directusFileUrl(
    file: Partial<Create_Directus_Files_Input> | undefined | null,
    params?: Record<string, string>,
) {
    if (!file || !file.id) {
        return null;
    }

    // https://docs.directus.io/reference/files.html#custom-transformations
    const defaultParams = {
        format: 'webp',
        quality: '75',
        access_token: DIRECTUS_TOKEN as string,
    };

    const urlParams = `?${new URLSearchParams({
        ...defaultParams,
        ...params,
    }).toString()}`;

    if (file.filename_download) {
        return normalizeUrl(
            `${DIRECTUS_URL}/assets/${file.id}/${file.filename_download}${urlParams}`,
        );
    }

    return normalizeUrl(`${DIRECTUS_URL}/assets/${file.id}${urlParams}`);
}

export const timeout = (timeoutTime = 2000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(1);
        }, timeoutTime);
    });
};

// cn stands for "class names".
// Combines multiple class names into a single string, removing any duplicates.
// It uses `clsx` to conditionally join class names and then `twMerge` to handle any Tailwind CSS-specific merging logic.
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import IconDOB from '@clientweb/src/assets/calendar.svg';
import IconEdit from '@clientweb/src/assets/edit.svg';
import IconEmail from '@clientweb/src/assets/email.svg';
import IconLocation from '@clientweb/src/assets/location_on.svg';
import IconPerson from '@clientweb/src/assets/person.svg';
import IconPhone from '@clientweb/src/assets/phone.svg';
import { memo, useContext } from 'react';

import { convertUnixTimestampToFormattedDate } from '@common/utils/dateAndTime/formatting';

interface IUserInfoProps {
    setEditedField: (field: string) => void;
}

const UserInfo = ({ setEditedField }: IUserInfoProps) => {
    const appRootControler = useContext(AppRootContext);
    if (!appRootControler?.getUserProfileQuery.data) return null;
    const {
        email,
        phone,
        firstName,
        lastName,
        pronoun,
        dateOfBirth,
        addressLine1,
        addressLine2,
        city,
        state,
        zipCode,
    } = appRootControler?.getUserProfileQuery.data;

    const handleEdit = (field: string) => {
        setEditedField(field);
    };
    return (
        <div className="flex flex-col gap-4 mb-12">
            <p className="flex gap-2 justify-center items-center text-[30px] font-bold ">
                <span>{`${firstName} ${lastName}`}</span>
                <img
                    src={IconEdit}
                    onClick={() => setEditedField('Name')}
                    alt="Edit"
                    className="opacity-60 mt-[1px] cursor-pointer"
                />
            </p>
            <div className="bg-chartreuse-100 rounded-md mx-auto w-full shadow-card">
                <div className="flex gap-3 px-4 py-2 items-center border-b border-b-shade-100">
                    <img src={IconPhone} alt="Phone" className="size-6" />
                    <p className="flex flex-col">
                        <span className="text-dark font-bold">
                            Phone Number
                        </span>
                        <span className="text-base text-shade-500">
                            {phone}
                        </span>
                    </p>
                </div>
                <div className="flex gap-3 px-4 py-2 items-center border-b border-b-shade-100">
                    <img src={IconEmail} alt="email" className="size-6" />
                    <p className="flex flex-col">
                        <span className="text-dark font-bold">Email</span>
                        {email ? (
                            <span className="text-base text-shade-500">
                                {email}
                            </span>
                        ) : (
                            <button
                                className="text-primary-800"
                                onClick={() => handleEdit('Email Address')}
                            >
                                Add email
                            </button>
                        )}
                    </p>
                    <img
                        src={IconEdit}
                        onClick={() => setEditedField('Email Address')}
                        alt="edit"
                        className="size-6 ml-auto cursor-pointer"
                    />
                </div>
                <div className="flex gap-3 px-4 py-2 items-center border-b border-b-shade-100">
                    <img src={IconDOB} alt="calendar" className="size-6" />
                    <p className="flex flex-col">
                        <span className="text-dark font-bold">
                            Date Of Birth
                        </span>
                        {dateOfBirth ? (
                            <span className="text-base text-shade-500">
                                {convertUnixTimestampToFormattedDate(
                                    dateOfBirth,
                                )}
                            </span>
                        ) : (
                            <button
                                className="text-primary-800"
                                onClick={() => handleEdit('Date of Birth')}
                            >
                                Add date of birth
                            </button>
                        )}
                    </p>
                    <img
                        src={IconEdit}
                        onClick={() => setEditedField('Date of Birth')}
                        alt="edit"
                        className="size-6 ml-auto cursor-pointer"
                    />
                </div>
                <div className="flex gap-3 px-4 py-2 items-center border-b border-b-shade-100">
                    <img src={IconLocation} alt="location" className="size-6" />
                    <div className="flex flex-col">
                        <span className="text-dark font-bold">Address</span>
                        {addressLine1 ? (
                            <p className="flex flex-col text-base text-shade-500">
                                <span>{`${addressLine1} ${addressLine2}`}</span>
                                <span>{`${city}, ${state} - ${zipCode}`}</span>
                            </p>
                        ) : (
                            <button
                                className="text-primary-800"
                                onClick={() => handleEdit('Address')}
                            >
                                Add address
                            </button>
                        )}
                    </div>
                    <img
                        src={IconEdit}
                        onClick={() => setEditedField('Address')}
                        alt="edit"
                        className="size-6 ml-auto cursor-pointer"
                    />
                </div>
                <div className="flex gap-3 px-4 py-2 items-center border-b border-b-shade-100">
                    <img src={IconPerson} alt="Phone" className="size-6" />
                    <p className="flex flex-col">
                        <span className="text-dark font-bold">Pronouns</span>
                        {pronoun ? (
                            <span className="text-base text-shade-500">
                                {pronoun}
                            </span>
                        ) : (
                            <button
                                className="text-primary-800"
                                onClick={() => handleEdit('Pronouns')}
                            >
                                Add pronouns
                            </button>
                        )}
                    </p>
                    <img
                        src={IconEdit}
                        onClick={() => setEditedField('Pronouns')}
                        alt="edit"
                        className="size-6 ml-auto cursor-pointer"
                    />
                </div>
            </div>
        </div>
    );
};

const Memoized = memo(UserInfo);
export default Memoized;

import EntryPointForQRCode from '@clientweb/src/routes/EntryPointForQRCode';
import Products from '@clientweb/src/routes/Products/Products';
import Profile from '@clientweb/src/routes/Profile/Profile';
import Review from '@clientweb/src/routes/Questionnaire/ReviewProduct/ReviewProduct';
import Rewards from '@clientweb/src/routes/Rewards/Rewards';
import Scan from '@clientweb/src/routes/Scan/Scan';
import AuthLogout from '@clientweb/src/routes/auth/AuthLogout';
import AuthRoute from '@clientweb/src/routes/auth/AuthRoute';
import ProtectedRoute from '@clientweb/src/routes/auth/ProtectedRoute';
import LoginWithPhone from '@clientweb/src/routes/auth/login/LoginWithPhone';
import RegisterWithPhone from '@clientweb/src/routes/auth/register/RegisterWithPhone';
import AuthLayout from '@clientweb/src/routes/layouts/AuthLayout';
import DefaultLayout from '@clientweb/src/routes/layouts/DefaultLayout/DefaultLayout';
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from 'react-router-dom';

import AppDataLayer from './AppData';
import TransitionScanSuccessful from './Scan/TransitionScanSuccessful';
import RootLayout from './layouts/RootLayout';

export const REDIRECT_AFTER_NOT_AUTHORIZED = '/auth/login';
export const REDIRECT_AFTER_LOGIN = '/products';

const router = createBrowserRouter([
    {
        id: 'root',
        path: '/',
        element: (
            <AppDataLayer>
                <RootLayout>
                    <DefaultLayout>
                        <ProtectedRoute />
                    </DefaultLayout>
                </RootLayout>
            </AppDataLayer>
        ),
        children: [
            {
                path: '/products',
                element: <Products />,
            },

            {
                path: '/profile',
                element: <Profile />,
            },
            {
                path: '/rewards',
                element: <Rewards />,
            },
            {
                path: '/scan',
                element: <Scan />,
            },
            {
                path: '/',
                element: <Navigate to="/products" replace />,
            },
        ],
    },
    {
        id: 'questionnaire',
        path: '/questionnaire',
        element: (
            <AppDataLayer>
                <RootLayout>
                    <ProtectedRoute />
                </RootLayout>
            </AppDataLayer>
        ),
        children: [
            {
                path: '/questionnaire/:campaignId/:productId/review',
                element: <Review />,
            },
            {
                path: '/questionnaire/:campaignId/review',
                element: <Review />,
            },
        ],
    },
    {
        id: 'transition',
        path: '/transition',
        element: (
            <AppDataLayer>
                <RootLayout>
                    <ProtectedRoute />
                </RootLayout>
            </AppDataLayer>
        ),
        children: [
            {
                path: '/transition/scan-success',
                element: <TransitionScanSuccessful />,
            },
        ],
    },
    {
        path: '/entry/code',
        element: (
            <AppDataLayer>
                <RootLayout>
                    <EntryPointForQRCode />
                </RootLayout>
            </AppDataLayer>
        ),
    },
    {
        id: 'auth',
        path: '/auth',
        element: (
            <AppDataLayer>
                <RootLayout>
                    <AuthLayout>
                        <AuthRoute />
                    </AuthLayout>
                </RootLayout>
            </AppDataLayer>
        ),
        children: [
            {
                path: '/auth/login',
                element: <LoginWithPhone />,
            },
            {
                path: '/auth/register',
                element: <RegisterWithPhone />,
            },
            {
                path: '/auth/logout',
                element: <AuthLogout />,
            },
        ],
    },
    {
        path: '*',
        element: <Navigate to="/auth/login" replace />,
    },
]);

const RootRouter = () => {
    return <RouterProvider router={router} />;
};

export default RootRouter;

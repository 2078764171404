import Brandmark from '@clientweb/src/assets/Samplefest Brandmark.svg';
import { cn } from '@clientweb/src/utils/frontend';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { buttonVariants } from '../../general/Button/Button';
import ScanSVG from './../../../../assets/bottomNav/qr_code_scanner.svg?react';

interface IBoxMessageProps {
    className?: string;
    titleText?: string;
    button?: {
        text: string;
        link: string;
    };
    instructionText?: string;
    buttonText?: string;
}

const BoxMessage = styled(
    ({ className, titleText, instructionText, button }: IBoxMessageProps) => {
        return (
            <div
                className={clsx([
                    'flex flex-col text-dark justify-center gap-4 mt-12 p-16',
                    className,
                ])}
            >
                <img
                    src={Brandmark}
                    alt="Brandmark"
                    className="mx-auto max-w-64"
                />
                <h3 className="font-bold text-custom-large text-center">
                    {titleText}
                </h3>
                <p className="text-base text-center max-w-64 mx-auto">
                    {instructionText}
                </p>
                {button ? (
                    <Link
                        to={button.link}
                        className={cn(
                            buttonVariants({ variant: 'primary' }),
                            'mx-auto max-w-80 flex gap-2 items-center text-2xl flex-1 h-10',
                        )}
                    >
                        {React.cloneElement(<ScanSVG />, {
                            className: 'size-8',
                        })}

                        <span className="text-2xl mb-[1px]">{button.text}</span>
                    </Link>
                ) : null}
            </div>
        );
    },
)`
    background-color: #f9fdeb;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

export default BoxMessage;

import React from 'react';

type SingleProps = {
    type: 'single';
    item: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedOption: string;
    isRadio?: boolean;
};

type MultipleProps = {
    type: 'multiple';
    item: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isRadio?: boolean;
    selectedOptions: Record<string, boolean>;
};

type CustomCheckboxProps = SingleProps | MultipleProps;

const CustomCheckbox = ({
    item,
    handleChange,
    type,
    isRadio,
    ...props
}: CustomCheckboxProps) => {
    let isSelected: boolean;

    if (type === 'single') {
        const { selectedOption } = props as SingleProps;
        isSelected = item === selectedOption;
    } else {
        const { selectedOptions } = props as MultipleProps;
        isSelected = selectedOptions[item] ?? false;
    }

    return (
        <label
            className={`w-full px-3 py-4 border rounded-md ${
                isSelected
                    ? 'bg-primary-100 border-primary'
                    : 'bg-transparent border-shade-200'
            }`}
        >
            <input
                className="hidden"
                type={isRadio ? 'radio' : 'checkbox'}
                value={item}
                checked={isSelected}
                onChange={handleChange}
            />
            <span
                className={`text-xl ${
                    isSelected
                        ? 'text-primary font-bold'
                        : 'text-dark font-normal'
                }`}
            >
                {item}
            </span>
        </label>
    );
};

export default CustomCheckbox;

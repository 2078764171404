import TileLine1Img from '@clientweb/src/assets/tile_line_1.png';
import TileLine2Img from '@clientweb/src/assets/tile_line_2.png';
import styled from 'styled-components';

export const TileLineWrapper = styled.img`
    min-height: 144px;
    height: 10vw;
    max-height: 160px;
    width: fit-content;
    max-width: unset;
`;

export { TileLine1Img, TileLine2Img };

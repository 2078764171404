import { CRM_STATE_LIST } from '@common/commonData/crmStateList';

export function parseAddress(place: google.maps.places.PlaceResult) {
    if (!place.address_components) {
        throw new Error('No address components found.');
    }

    let streetNumber = '';
    let route = '';
    let subPremise = '';
    let locality = '';
    let administrativeArea = '';
    let postalCode = '';
    let postalCodeSuffix = '';
    let country = '';

    // Parse address components
    place.address_components.forEach((component) => {
        if (component.types.includes('street_number')) {
            streetNumber = component.long_name;
        }
        if (component.types.includes('route')) {
            route = component.short_name; // Use short_name for abbreviations like "St"
        }
        if (component.types.includes('subpremise')) {
            subPremise = `Ste ${component.long_name}`; // Example: "Ste 10"
        }
        if (component.types.includes('locality')) {
            locality = component.long_name; // City
        }
        if (component.types.includes('administrative_area_level_1')) {
            administrativeArea = component.long_name; // State abbreviation
        }
        if (component.types.includes('postal_code')) {
            postalCode = component.long_name;
        }
        if (component.types.includes('postal_code_suffix')) {
            postalCodeSuffix = component.long_name;
        }
        if (component.types.includes('country')) {
            country = component.long_name;
        }
    });

    const crmState = CRM_STATE_LIST.find(
        (entry) => entry.fullName === administrativeArea,
    );
    const [addressLine1, ...addressLine2Array] =
        place?.formatted_address?.split(',') ?? [];
    const addressLine2 = addressLine2Array.join(',');

    return {
        addressLine1: addressLine1 ?? '',
        addressLine2,
        country,
        city: locality,
        rawState: administrativeArea,
        parsedState: crmState,
        zipCode: postalCode,
    };
}

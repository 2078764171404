import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { firebaseClientAppAuth as auth } from '@clientweb/src/firebase.client';
import {
    ConfirmationResult,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from 'firebase/auth';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as UAParser from 'ua-parser-js';
import { z } from 'zod';

export const OTP_LENGTH = 6;
export const MIN_PHONE_LENGTH = 8;

const ISD_CODES_CONFIGS = [
    { code: '+1-', maxPhoneLength: 10 },
    { code: '+48-', maxPhoneLength: 9 },
];

function getUserEnvironmentInfo() {
    const uap = new UAParser.UAParser();
    const results = uap.getResult();
    return results;
}

const getISDCodeConfig = (
    code: number,
): { code: string; maxPhoneLength: number } | undefined => {
    const foundConfig = ISD_CODES_CONFIGS.find(
        (config) => config.code === `+${code}-`,
    );
    if (foundConfig) return foundConfig;
    return { code: `+${code}-`, maxPhoneLength: 20 };
};

export const useLoginWithPhoneController = () => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isNewNumber, setIsNewNumber] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [verificationCode, setVerificationCode] = useState<string>('');
    const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
    const [confirmationResult, setConfirmationResult] =
        useState<ConfirmationResult | null>(null);
    const [message, setMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();

    // References for each input field
    const inputRefs = useRef<HTMLInputElement[]>([]);
    const otpRef = useRef<HTMLFormElement>(null);
    const phoneInputRef = useRef<HTMLInputElement>(null);

    const phonePrefix = (() => {
        const url = new URL(window.location.href);
        const forcedPrefix = url.searchParams.get('forcePrefix');
        return forcedPrefix ? Number(forcedPrefix) : 1;
    })();

    const isdCodeConfig = getISDCodeConfig(phonePrefix);

    useEffect(() => {
        if (otpRef.current && isCodeSent && inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
        if (phoneInputRef.current) {
            phoneInputRef.current.focus();
        }
    }, [isCodeSent, otpRef]);

    // Set up reCAPTCHA
    const setupRecaptcha = () => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                'recaptcha-container',
                {
                    size: 'invisible',
                    callback: () => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                    },
                },
            );
        }
    };

    // Handle sending the verification code
    const handleSendCode = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setMessage(null);

        setupRecaptcha(); // Set up reCAPTCHA
        setIsLoading(true);
        try {
            const fullPhoneNumber = isdCodeConfig?.code + phoneNumber;
            const totalMaxLength = (() => {
                const tmp =
                    (isdCodeConfig?.code?.length ?? 0) +
                    (isdCodeConfig?.maxPhoneLength ?? 0);
                if (tmp > 0) return tmp;
                return 20;
            })();

            const phoneValidation = (() => {
                const phoneSchema = z
                    .string()
                    .min(8, { message: 'Phone number is too short' })
                    .max(totalMaxLength, {
                        message: `Phone number must be up to ${totalMaxLength} digits long.`,
                    })
                    .refine((val) => val.startsWith(`${isdCodeConfig?.code}`), {
                        message: `Phone number must start with ${isdCodeConfig?.code}`,
                    });

                // Usage example
                const result = phoneSchema.safeParse(fullPhoneNumber);
                if (!result.success) {
                    return result.error.errors;
                } else {
                    return null;
                }
            })();
            if (phoneValidation) {
                setError(
                    phoneValidation.map((item) => item.message).join('\n'),
                    // `Phone number must be ${isdCodeConfig?.phoneLength} digits long.`,
                );
                return;
            }

            const appVerifier = window.recaptchaVerifier;
            if (!appVerifier) throw new Error('Missing App Verifier');

            const isPhoneNumberExisting =
                await trpcVanilla.userRouter.v1.isPhoneNumberExisting.query({
                    phone: fullPhoneNumber,
                });

            setIsNewNumber(!isPhoneNumberExisting);
            //Just for now, will be refactored after Firebase billing issue fix
            const result = await signInWithPhoneNumber(
                auth,
                fullPhoneNumber.replaceAll('-', ''),
                appVerifier,
            );

            setConfirmationResult(result);
            setIsCodeSent(true);
            setMessage('Verification code sent to your phone.');
        } catch (err) {
            setError('Something went wrong. Verify number and try again.');
        } finally {
            setIsLoading(false);
        }
    };

    // Handle verifying the OTP code
    const handleVerifyCode = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);
        setMessage(null);

        if (!confirmationResult) {
            setError('No verification code has been sent.');
            return;
        }

        try {
            const firebaseRes = await confirmationResult.confirm(
                verificationCode,
            );
            setMessage('Code verified successfully!');

            if (isNewNumber) {
                const userPlatformData = getUserEnvironmentInfo();
                await trpcVanilla.userRouter.v1.create.mutate({
                    firebaseId: firebaseRes.user.uid,
                    phone: isdCodeConfig?.code + phoneNumber,
                    platformData: JSON.stringify(userPlatformData),
                });

                return navigate('/auth/register');
            }
            navigate(`/products`);
        } catch (err) {
            setError('Oops! Something went wrong. Please try again.');
        }
    };

    const onOTPDone = (code: string) => {
        setVerificationCode(code);
        setTimeout(() => otpRef?.current?.requestSubmit(), 100);
    };

    return {
        onOTPDone,
        isCodeSent,
        isLoading,
        handleSendCode,
        phoneInputRef,
        isdCodeConfig,
        phoneNumber,
        setPhoneNumber,
        handleVerifyCode,
        otpRef,
        isNewNumber,
        error,
        message,
        phonePrefix,
        verificationCode,
        inputRefs,
    };
};

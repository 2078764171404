import { cn } from '@clientweb/src/utils/frontend';
import { memo, useContext, useMemo } from 'react';
import styled from 'styled-components';

import { ReviewContext } from '../ReviewProduct';
import ReviewQuestionMultiResponse from './MultiSelect';
import ReviewQuestionScale from './Scale';
import ReviewQuestionSingleResponse from './SingleSelect';
import ReviewQuestionString from './String';

export interface IReviewQuestionProps {
    className?: string;
}

const ReviewQuestion = styled(({ className }: IReviewQuestionProps) => {
    const reviewController = useContext(ReviewContext);

    const questionComponent = useMemo(() => {
        const questionType =
            reviewController?.currentQuestion?.SampleFestProductQuestions_id
                ?.type;

        switch (questionType) {
            case 'multi-response-checkbox':
                return <ReviewQuestionMultiResponse />;
            case 'single-response-checkbox':
                return <ReviewQuestionSingleResponse />;
            case 'integer-scale':
                return <ReviewQuestionScale />;
            case 'string':
                return <ReviewQuestionString />;
        }
        return null;
    }, [reviewController?.currentQuestion]);

    return (
        <div className={cn('p-2', className)}>
            <p className="text-[32px] leading-[40px] font-bold text-dark">
                {
                    reviewController?.currentQuestion
                        ?.SampleFestProductQuestions_id?.question
                }
            </p>
            <div className="mt-4">{questionComponent}</div>
        </div>
    );
})``;

const Memoized = memo(ReviewQuestion);
const Styled = styled(Memoized)``;
export default Styled;

import InstagramIcon from '@clientweb/src/assets/icons/instagram-icon.png';
import SnapchatIcon from '@clientweb/src/assets/icons/snap-icon.png';
import Link from '@clientweb/src/assets/link.svg';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { memo } from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
import styled from 'styled-components';

export interface IShareModalProps {
    className?: string;
    shareUrl: string;
    navigatorShareOptions?: ShareData;
    onClose: () => void;
}

const ShareModal = ({
    className,
    navigatorShareOptions,
    onClose,
    shareUrl,
}: IShareModalProps) => {
    const handleCopy = () => {
        navigator.clipboard
            .writeText(shareUrl)
            .then(() => {
                alert('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    // Open Snapchat share URL
    const handleSnapchatShare = () => {
        window.open(
            `https://www.snapchat.com/scan?attachmentUrl=${encodeURIComponent(
                shareUrl,
            )}`,
            '_blank',
        );
    };

    // Open Instagram app (user will have to share manually)
    const handleInstagramShare = async () => {
        try {
            // Fetch the image and convert it to a Blob

            // Check if sharing is supported and share the file
            if (
                navigator.canShare &&
                navigator.canShare(navigatorShareOptions)
            ) {
                await navigator.share(navigatorShareOptions);
            } else {
                alert('Sharing is not supported on your browser.');
            }
        } catch (err) {
            console.error('Error sharing the image: ', err);
        }
    };

    return (
        <div className="absolute bg-white shadow-card">
            <header className="">
                <Button
                    onClick={onClose}
                    variant="delete"
                    aria-label="close"
                ></Button>
            </header>
            <section className="modal-card-body">
                <p className="font-bold text-dark text-2xl text-center">
                    Share the love!
                </p>
                <div className="flex justify-center gap-4 mt-4 mb-4 ">
                    <FacebookShareButton url={shareUrl}>
                        <FacebookIcon size={40} round={true} />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl}>
                        <TwitterIcon size={40} round={true} />
                    </TwitterShareButton>
                    <WhatsappShareButton url={shareUrl}>
                        <WhatsappIcon size={40} round={true} />
                    </WhatsappShareButton>
                    <button
                        onClick={handleSnapchatShare}
                        className="is-snapchat"
                    >
                        <img
                            src={SnapchatIcon}
                            alt="Snapchat"
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                            }}
                        />
                    </button>
                    <button
                        onClick={handleInstagramShare}
                        className="is-instagram"
                    >
                        <img
                            src={InstagramIcon}
                            alt="Instagram"
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: '50%',
                            }}
                        />
                    </button>
                </div>
                <div className="flex flex-col gap-2 pt-3">
                    <p className="text-xl text-[#02473E] text-center font-[400]">
                        OR
                    </p>

                    <Button className="flex items-center" onClick={handleCopy}>
                        <img src={Link} alt="link" className="mr-2 mt-1" />
                        Copy
                    </Button>
                </div>
            </section>
        </div>
    );
};

const Memoized = memo(ShareModal);
const Styled = styled(Memoized)``;
export default Styled;

import { cn } from '@clientweb/src/utils/frontend';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

const inputVariants = cva('', {
    variants: {
        dimension: {
            sm: 'is-normal',
            md: 'is-medium',
        },
        isError: {
            true: 'border-danger text-danger',
            false: '',
        },
    },
    defaultVariants: {
        dimension: 'md',
        isError: false,
    },
});

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
    VariantProps<typeof inputVariants>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, type, dimension, name, isError, ...props }, ref) => {
        const variantStyle = inputVariants({
            dimension,
            className,
            isError,
        });
        const classes = cn(
            'input border text-dark border-primary-400 focus:border-primary placeholder-primary-400',
            variantStyle,
            className,
        );
        return (
            <input
                type={type}
                name={name}
                className={classes}
                ref={ref}
                {...props}
            />
        );
    },
);

export { Input, inputVariants };

import Brandmark from '@clientweb/src/assets/Samplefest Brandmark.svg';
import ShareBox from '@clientweb/src/components/ShareBox/ShareBox';
import {
    Button,
    buttonVariants,
} from '@clientweb/src/design/v1/general/Button/Button';
import { memo, useEffect, useRef, useState } from 'react';
// import ReactConfettiCanvas from 'react-canvas-confetti';
import ReactConfettiCanvas from 'react-canvas-confetti/dist/presets/fireworks/index';
import { TOnInitPresetFn } from 'react-canvas-confetti/dist/types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface IAllProductReviewCompletePopUpProps {
    className?: string;
}

const AllProductReviewCompletePopUp = ({
    className,
}: IAllProductReviewCompletePopUpProps) => {
    const [countFiredStars, setCountFiredStars] = useState(0);
    const [reactConfettiAPI, setReactConfettiAPI] = useState<
        null | Parameters<TOnInitPresetFn>[0]
    >(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const body = document.querySelector('body');
        if (body) {
            body.style.overflow = 'hidden';
        }

        return () => {
            const body = document.querySelector('body');
            if (body) {
                body.style.overflow = 'auto';
            }
        };
    }, []);

    useEffect(() => {
        if (!reactConfettiAPI) return;
        if (countFiredStars > 0) return;
        setCountFiredStars(1);
        setTimeout(() => {
            // https://ulitcos.github.io/react-canvas-confetti/
            reactConfettiAPI?.confetti({
                shapes: ['star'],
                colors: ['#eebf7b'],
                ticks: 700,
                particleCount: 200,
                startVelocity: 70,
                gravity: 2,
            });
        }, 200);
    }, [countFiredStars, reactConfettiAPI]);

    return (
        <div
            className={`absolute inset-0 overflow-hidden grid place-items-center bg-primary-overlay z-[101] px-8 ${className}`}
        >
            <div
                ref={containerRef}
                className="absolute flex flex-col gap-5 p-5 bg-secondary-bg z-[102] max-w-96 rounded-md"
            >
                <ReactConfettiCanvas
                    onInit={(reactConfettiAPI) =>
                        setReactConfettiAPI(reactConfettiAPI)
                    }
                    className="absolute top-0 left-0 z-[150] pointer-events-none"
                    width={containerRef?.current?.clientWidth ?? 200}
                    height={containerRef?.current?.clientHeight ?? 200}
                    globalOptions={{
                        useWorker: true,
                    }}
                />
                <img
                    src={Brandmark}
                    alt="avatar"
                    width={186}
                    className="mx-auto"
                />
                <div className="flex flex-col gap-2">
                    <h3 className="text-center text-dark text-[32px] font-bold leading-[40px] font-outfit">
                        Congratulations!
                    </h3>
                    <p className="font-roboto font-[400] text-xl text-center text-dark">
                        You've won 10% off on your next purchase at Hyvee
                    </p>
                </div>
                <Link
                    to="/rewards"
                    className={buttonVariants({ variant: 'primary' })}
                >
                    GO TO REWARDS
                </Link>
                <ShareBox />
            </div>
        </div>
    );
};

const Memoized = memo(AllProductReviewCompletePopUp);
const Styled = styled(Memoized)``;
export default Styled;

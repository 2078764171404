export const CRM_STATES = {
    al: {
        isCanadian: false,
        stateKey: 'al',
        stateName: 'AL',
        fullName: 'Alabama',
    },
    ak: {
        isCanadian: false,
        stateKey: 'ak',
        stateName: 'AK',
        fullName: 'Alaska',
    },
    az: {
        isCanadian: false,
        stateKey: 'az',
        stateName: 'AZ',
        fullName: 'Arizona',
    },
    ar: {
        isCanadian: false,
        stateKey: 'ar',
        stateName: 'AR',
        fullName: 'Arkansas',
    },
    ca: {
        isCanadian: false,
        stateKey: 'ca',
        stateName: 'CA',
        fullName: 'California',
    },
    co: {
        isCanadian: false,
        stateKey: 'co',
        stateName: 'CO',
        fullName: 'Colorado',
    },
    ct: {
        isCanadian: false,
        stateKey: 'ct',
        stateName: 'CT',
        fullName: 'Connecticut',
    },
    de: {
        isCanadian: false,
        stateKey: 'de',
        stateName: 'DE',
        fullName: 'Delaware',
    },
    fl: {
        isCanadian: false,
        stateKey: 'fl',
        stateName: 'FL',
        fullName: 'Florida',
    },
    ga: {
        isCanadian: false,
        stateKey: 'ga',
        stateName: 'GA',
        fullName: 'Georgia',
    },
    hi: {
        isCanadian: false,
        stateKey: 'hi',
        stateName: 'HI',
        fullName: 'Hawaii',
    },
    id: {
        isCanadian: false,
        stateKey: 'id',
        stateName: 'ID',
        fullName: 'Idaho',
    },
    il: {
        isCanadian: false,
        stateKey: 'il',
        stateName: 'IL',
        fullName: 'Illinois',
    },
    in: {
        isCanadian: false,
        stateKey: 'in',
        stateName: 'IN',
        fullName: 'Indiana',
    },
    ia: {
        isCanadian: false,
        stateKey: 'ia',
        stateName: 'IA',
        fullName: 'Iowa',
    },
    ks: {
        isCanadian: false,
        stateKey: 'ks',
        stateName: 'KS',
        fullName: 'Kansas',
    },
    ky: {
        isCanadian: false,
        stateKey: 'ky',
        stateName: 'KY',
        fullName: 'Kentucky',
    },
    la: {
        isCanadian: false,
        stateKey: 'la',
        stateName: 'LA',
        fullName: 'Louisiana',
    },
    me: {
        isCanadian: false,
        stateKey: 'me',
        stateName: 'ME',
        fullName: 'Maine',
    },
    md: {
        isCanadian: false,
        stateKey: 'md',
        stateName: 'MD',
        fullName: 'Maryland',
    },
    ma: {
        isCanadian: false,
        stateKey: 'ma',
        stateName: 'MA',
        fullName: 'Massachusetts',
    },
    mi: {
        isCanadian: false,
        stateKey: 'mi',
        stateName: 'MI',
        fullName: 'Michigan',
    },
    mn: {
        isCanadian: false,
        stateKey: 'mn',
        stateName: 'MN',
        fullName: 'Minnesota',
    },
    ms: {
        isCanadian: false,
        stateKey: 'ms',
        stateName: 'MS',
        fullName: 'Mississippi',
    },
    mo: {
        isCanadian: false,
        stateKey: 'mo',
        stateName: 'MO',
        fullName: 'Missouri',
    },
    mt: {
        isCanadian: false,
        stateKey: 'mt',
        stateName: 'MT',
        fullName: 'Montana',
    },
    ne: {
        isCanadian: false,
        stateKey: 'ne',
        stateName: 'NE',
        fullName: 'Nebraska',
    },
    nv: {
        isCanadian: false,
        stateKey: 'nv',
        stateName: 'NV',
        fullName: 'Nevada',
    },
    nh: {
        isCanadian: false,
        stateKey: 'nh',
        stateName: 'NH',
        fullName: 'New Hampshire',
    },
    nj: {
        isCanadian: false,
        stateKey: 'nj',
        stateName: 'NJ',
        fullName: 'New Jersey',
    },
    nm: {
        isCanadian: false,
        stateKey: 'nm',
        stateName: 'NM',
        fullName: 'New Mexico',
    },
    ny: {
        isCanadian: false,
        stateKey: 'ny',
        stateName: 'NY',
        fullName: 'New York',
    },
    nc: {
        isCanadian: false,
        stateKey: 'nc',
        stateName: 'NC',
        fullName: 'North Carolina',
    },
    nd: {
        isCanadian: false,
        stateKey: 'nd',
        stateName: 'ND',
        fullName: 'North Dakota',
    },
    oh: {
        isCanadian: false,
        stateKey: 'oh',
        stateName: 'OH',
        fullName: 'Ohio',
    },
    ok: {
        isCanadian: false,
        stateKey: 'ok',
        stateName: 'OK',
        fullName: 'Oklahoma',
    },
    or: {
        isCanadian: false,
        stateKey: 'or',
        stateName: 'OR',
        fullName: 'Oregon',
    },
    pa: {
        isCanadian: false,
        stateKey: 'pa',
        stateName: 'PA',
        fullName: 'Pennsylvania',
    },
    ri: {
        isCanadian: false,
        stateKey: 'ri',
        stateName: 'RI',
        fullName: 'Rhode Island',
    },
    sc: {
        isCanadian: false,
        stateKey: 'sc',
        stateName: 'SC',
        fullName: 'South Carolina',
    },
    sd: {
        isCanadian: false,
        stateKey: 'sd',
        stateName: 'SD',
        fullName: 'South Dakota',
    },
    tn: {
        isCanadian: false,
        stateKey: 'tn',
        stateName: 'TN',
        fullName: 'Tennessee',
    },
    tx: {
        isCanadian: false,
        stateKey: 'tx',
        stateName: 'TX',
        fullName: 'Texas',
    },
    ut: {
        isCanadian: false,
        stateKey: 'ut',
        stateName: 'UT',
        fullName: 'Utah',
    },
    vt: {
        isCanadian: false,
        stateKey: 'vt',
        stateName: 'VT',
        fullName: 'Vermont',
    },
    va: {
        isCanadian: false,
        stateKey: 'va',
        stateName: 'VA',
        fullName: 'Virginia',
    },
    wa: {
        isCanadian: false,
        stateKey: 'wa',
        stateName: 'WA',
        fullName: 'Washington',
    },
    wv: {
        isCanadian: false,
        stateKey: 'wv',
        stateName: 'WV',
        fullName: 'West Virginia',
    },
    wi: {
        isCanadian: false,
        stateKey: 'wi',
        stateName: 'WI',
        fullName: 'Wisconsin',
    },
    wy: {
        isCanadian: false,
        stateKey: 'wy',
        stateName: 'WY',
        fullName: 'Wyoming',
    },
    dc: {
        isCanadian: false,
        stateKey: 'dc',
        stateName: 'DC',
        fullName: 'District of Columbia',
    },
    bc: {
        isCanadian: true,
        stateKey: 'bc',
        stateName: 'BC',
        fullName: 'British Columbia',
    },
    mb: {
        isCanadian: true,
        stateKey: 'mb',
        stateName: 'MB',
        fullName: 'Manitoba',
    },
    nb: {
        isCanadian: true,
        stateKey: 'nb',
        stateName: 'NB',
        fullName: 'New Brunswick',
    },
    nl: {
        isCanadian: true,
        stateKey: 'nl',
        stateName: 'NL',
        fullName: 'Newfoundland and Labrador',
    },
    ns: {
        isCanadian: true,
        stateKey: 'ns',
        stateName: 'NS',
        fullName: 'Nova Scotia',
    },
    on: {
        isCanadian: true,
        stateKey: 'on',
        stateName: 'ON',
        fullName: 'Ontario',
    },
    pe: {
        isCanadian: true,
        stateKey: 'pe',
        stateName: 'PE',
        fullName: 'Prince Edward Island',
    },
    qc: {
        isCanadian: true,
        stateKey: 'qc',
        stateName: 'QC',
        fullName: 'Quebec',
    },
    sk: {
        isCanadian: true,
        stateKey: 'sk',
        stateName: 'SK',
        fullName: 'Saskatchewan',
    },
    other: {
        stateKey: 'other',
        stateName: 'Other',
        fullName: 'Other',
    },
};

export const CRM_STATE_LIST = Object.values(CRM_STATES);

export type CRM_STATE_KEYS = keyof typeof CRM_STATES;

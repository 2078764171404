import { getAuth, signOut } from 'firebase/auth';
import { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { REDIRECT_AFTER_NOT_AUTHORIZED } from '../Router';

export interface IAuthLogoutProps {
    className?: string;
}

const AuthLogout = ({ className }: IAuthLogoutProps) => {
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            const auth = getAuth();

            await signOut(auth).then(() => {
                if (window.recaptchaVerifier) {
                    window.recaptchaVerifier.clear();
                    window.recaptchaVerifier = undefined;
                }
            });
            navigate(REDIRECT_AFTER_NOT_AUTHORIZED);
        })();
    }, []);
    return <div className={className} />;
};

const Memoized = memo(AuthLogout);
const Styled = styled(Memoized)``;
export default Styled;

import { trpcReact } from '@clientweb/src/AppRoot/trpcClient.react';
import useModal from '@clientweb/src/utils/react';
import { usePortalTarget } from '@clientweb/src/utils/react';
import { useState } from 'react';

export const useDefaultLayoutController = () => {
    const activeCampaignQuestionarieQuery =
        trpcReact.campaignRouter.v1.getActiveCampaignQuestionarie.useQuery();

    const campaignQuestionModal = useModal();
    const portalTarget = usePortalTarget('#before-footer-portal');
    const [isCampaignLevelBannerDismissed, setIsCampaignLevelBannerDismissed] =
        useState(false);

    const onTriggerCampaignLevelModal = () => {
        campaignQuestionModal.openModal();
    };

    return {
        activeCampaignQuestionarieQuery,
        campaignQuestionModal,
        portalTarget,
        onTriggerCampaignLevelModal,
        isCampaignLevelBannerDismissed,
        setIsCampaignLevelBannerDismissed,
    };
};

export default useDefaultLayoutController;

import Dissatisfied from '@clientweb/src/assets/icons/sentiment_dissatisfied.svg';
import Neutral from '@clientweb/src/assets/icons/sentiment_neutral.svg';
import Satisfied from '@clientweb/src/assets/icons/sentiment_satisfied.svg';
import VeryDissatisfied from '@clientweb/src/assets/icons/sentiment_very_dissatisfied.svg';
import VerySatisfied from '@clientweb/src/assets/icons/sentiment_very_satisfied.svg';
import React, { useContext, useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import styled from 'styled-components';

import { ReviewContext } from '../ReviewProduct';
import CustomCheckbox from './CustomCheckbox';
import TextArea from './TextArea';

const ReviewQuestionScale = styled(({ className }) => {
    const reviewController = useContext(ReviewContext);

    const maxScale =
        reviewController?.currentQuestion?.SampleFestProductQuestions_id
            ?.scalemax ?? 5;
    const minScale =
        reviewController?.currentQuestion?.SampleFestProductQuestions_id
            ?.scalemin ?? 0;

    const [value, setValue] = useState<number>(
        (reviewController?.answerForCurrentQuestion?.answers as number) ?? 0,
    );
    const [noAnswerCheckboxValue, setNoAnswerCheckboxValue] = useState(
        reviewController?.currentQuestion?.SampleFestProductQuestions_id
            ?.no_answer
            ? reviewController?.answerForCurrentQuestion?.noAnswer
            : false,
    );
    const [commentValue, setCommentValue] = useState(
        reviewController?.answerForCurrentQuestion?.comments ?? '',
    );
    const handleCommentChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        const value = event.target.value as string;
        setCommentValue(value);
    };

    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setNoAnswerCheckboxValue(event.target.checked);
        setValue(minScale);
    };

    useEffect(() => {
        const onSave = () => {
            reviewController?.saveQuestion({
                noAnswer: noAnswerCheckboxValue ? true : null,
                value,
                comment: commentValue,
            });
            reviewController?.continueFlow();
        };
        reviewController?.mittEventEmitter?.on('save', onSave);

        return () => reviewController?.mittEventEmitter?.off('save', onSave);
    }, [reviewController, noAnswerCheckboxValue, commentValue, value]);

    const valueIcon = (() => {
        switch (value) {
            case 0:
                return null;
            case 1:
                return VeryDissatisfied;
            case 2:
                return Dissatisfied;
            case 3:
                return Neutral;
            case 4:
                return Satisfied;
            case 5:
                return VerySatisfied;
            default:
                return null;
        }
    })();

    return (
        <div className={className}>
            <div className="flex gap-3 items-center justify-center font-bold text-2xl h-14 text-dark mt-3 -mb-3">
                {valueIcon && <img src={valueIcon} alt="icon" />}
                {value === 0 ? 'Select a rating below' : `${value}/${maxScale}`}
            </div>
            {/* https://zillow.github.io/react-slider/ */}
            <section className="arc-slider">
                <ReactSlider
                    className="horizontal-slider"
                    markClassName="slider-mark"
                    thumbClassName="slider-thumb"
                    trackClassName="slider-track"
                    min={minScale}
                    max={maxScale}
                    disabled={!!noAnswerCheckboxValue}
                    defaultValue={
                        reviewController?.answerForCurrentQuestion
                            ?.answers as number
                    }
                    value={value}
                    onChange={(valueNow) => setValue(valueNow)}
                    renderThumb={(props, state) => {
                        const { key, ...rest } = props;
                        return (
                            <div key={key} {...rest}>
                                {state.valueNow}
                            </div>
                        );
                    }}
                />
            </section>

            {reviewController?.currentQuestion?.SampleFestProductQuestions_id
                ?.no_answer ? (
                <CustomCheckbox
                    type="single"
                    item={
                        reviewController?.currentQuestion
                            ?.SampleFestProductQuestions_id?.no_answer_text ??
                        ''
                    }
                    handleChange={handleCheckboxChange}
                    selectedOption={
                        noAnswerCheckboxValue
                            ? reviewController?.currentQuestion
                                  ?.SampleFestProductQuestions_id
                                  ?.no_answer_text ?? ''
                            : ''
                    }
                />
            ) : null}
            {reviewController?.currentQuestion?.SampleFestProductQuestions_id
                ?.comments ? (
                <TextArea
                    handleChange={handleCommentChange}
                    value={commentValue}
                    placeholder="Comments (optional)..."
                />
            ) : null}
        </div>
    );
})`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    .arc-slider {
        width: 100%;
        .horizontal-slider {
            height: 50px;

            &.disabled {
                & .slider-thumb {
                    cursor: not-allowed;

                    z-index: 999;
                }
            }
        }

        .slider-track {
            background: #ececec;
            top: 20px;
            height: 24px;
            border-radius: 32px;
        }

        .slider-track.slider-track-0 {
            background: #03594d;
        }

        .slider-thumb {
            top: 16px;
            width: 32px;
            height: 32px;
            line-height: 38px;
            font-size: 0.9em;
            text-align: center;
            background-color: white;
            color: white;
            cursor: pointer;
            border: 1.6px solid #03594d;
            border-radius: 50%;
            box-sizing: border-box;
        }
    }
`;

export default ReviewQuestionScale;

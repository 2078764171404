import { trpcReact } from '@clientweb/src/AppRoot/trpcClient.react';
import { useSearchParams } from 'react-router-dom';

export const useProductsController = () => {
    const productListQuery =
        trpcReact.productRouter.v1.getListForUser.useQuery();
    const [searchParams] = useSearchParams();
    const reviewSuccess = searchParams.get('reviewSuccess') === 'true';

    return {
        searchParams,
        productListQuery,
        reviewSuccess,
    };
};

export default useProductsController;

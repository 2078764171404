import { trpcReact } from '@clientweb/src/AppRoot/trpcClient.react';
import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import useModal from '@clientweb/src/utils/react';
import mitt from 'mitt';
import React, { useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PRODUCT_STATUS } from '@common/business/product/types';

import { DefaultLayoutContext } from '../../layouts/DefaultLayout/DefaultLayout';

export const useReviewController = () => {
    const navigate = useNavigate();
    const { productId, campaignId } = useParams();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [mittEventEmitter] = useState(mitt());
    const [isBigImageCarouselOpen, setIsBigImageCarouselOpen] = useState(false);
    const appRootController = useContext(AppRootContext);
    const defaultLayoutController = useContext(DefaultLayoutContext);
    const productDetailsSection = useModal();

    const saveAnswerMutation =
        trpcReact.productRouter.v1.saveAnswer.useMutation();
    const saveCampaignAnswerMutation =
        trpcReact.productRouter.v1.saveCampaignReviewAnswer.useMutation();
    const markQuestionareAsCompletedMutation =
        trpcReact.productRouter.v1.markQuestionareAsCompleted.useMutation();

    if (!campaignId) throw new Error('Missing campaignId');

    const getProductQuery = productId
        ? trpcReact.productRouter.v1.getProductForReview.useQuery({
              productId,
              campaignId,
          })
        : null;

    const getSingleCampaign =
        trpcReact.productRouter.v1.getSingleCampaign.useQuery({
            campaignId,
        });

    const questions = useMemo(() => {
        if (productId) {
            return getProductQuery?.data?.questions;
        }
        return getSingleCampaign.data?.campaign.questions;
    }, [
        getProductQuery?.data?.questions,
        getSingleCampaign.data?.campaign.questions,
        productId,
    ]);

    const currentQuestion = useMemo(() => {
        return questions?.[currentQuestionIndex];
    }, [questions, currentQuestionIndex]);

    const answersFromBackend = useMemo(() => {
        const answers = (() => {
            if (productId) {
                return getProductQuery?.data?.currentQuestionarie;
            }
            return getSingleCampaign.data?.campaign.questionarie;
        })();
        return answers;
    }, [
        getProductQuery?.data?.currentQuestionarie,
        getSingleCampaign.data?.campaign.questionarie,
        productId,
    ]);

    const answerForCurrentQuestion = useMemo(() => {
        const result =
            answersFromBackend?.answers?.[
                currentQuestion?.SampleFestProductQuestions_id?.id ?? ''
            ];
        return result;
    }, [currentQuestion, answersFromBackend]);

    const isLastQuestion = useMemo(() => {
        return (questions?.length ?? 1) - 1 === currentQuestionIndex;
    }, [questions?.length, currentQuestionIndex]);

    const continueFlow = () => {
        if (isLastQuestion) return;
        setCurrentQuestionIndex(currentQuestionIndex + 1);
    };

    const saveQuestion = async ({
        value,
        comment,
        noAnswer,
    }: {
        noAnswer: true | null;
        value: unknown;
        comment?: string;
    }) => {
        if (!currentQuestion?.SampleFestProductQuestions_id?.id)
            throw new Error('Missing active question');
        if (productId) {
            saveAnswerMutation.mutate({
                campaignId,
                productId,
                questionId: currentQuestion?.SampleFestProductQuestions_id?.id,
                value,
                noAnswer,
                comment: (comment?.length ?? 0) > 1 ? comment : undefined,
            });
        } else {
            saveCampaignAnswerMutation.mutate({
                campaignId,
                questionId: currentQuestion?.SampleFestProductQuestions_id?.id,
                value,
                noAnswer,
                comment: (comment?.length ?? 0) > 1 ? comment : undefined,
            });
        }

        if (productId) {
            getProductQuery?.refetch();
        }
        getSingleCampaign?.refetch();

        if (!isLastQuestion) {
            return;
        }
        markQuestionareAsCompletedMutation.mutate({
            campaignId,
            productId,
        });
        await defaultLayoutController?.activeCampaignQuestionarieQuery.refetch();
        const newProductList = await getSingleCampaign.refetch();

        const remainingProductsToReview = newProductList.data?.products?.filter(
            (item) => item.status !== PRODUCT_STATUS.REVIEW_DONE,
        );
        appRootController?.getNumberOfProductsLeftForReviewQuery.refetch();
        if ((remainingProductsToReview?.length ?? 0) > 0) {
            return navigate(
                `/products?reviewSuccess=true&productId=${productId}&campaignId=${campaignId}`,
            );
        }
        return navigate(
            `/products?reviewAllSuccess=true&campaignId=${campaignId}`,
        );
    };

    const onOpenBigImageCarousel = (event: React.MouseEvent<HTMLElement>) => {
        setIsBigImageCarouselOpen(true);
    };

    const onCloseBigImageCarousel = (event: React.MouseEvent<HTMLElement>) => {
        setIsBigImageCarouselOpen(false);
    };

    const isDataFetched = getSingleCampaign?.isFetched;
    const thumbnailUrl = useMemo(() => {
        if (productId) {
            return getProductQuery?.data?.main_image;
        }
        return null;
    }, [getProductQuery?.data?.main_image, productId]);

    return {
        questions,
        getProductQuery,
        isDataFetched,
        thumbnailUrl,
        productDetailsSection,
        isBigImageCarouselOpen,
        onOpenBigImageCarousel,
        onCloseBigImageCarousel,
        answerForCurrentQuestion,
        answersFromBackend,
        saveQuestion,
        isLastQuestion,
        continueFlow,
        productId,
        campaignId,
        currentQuestion,
        currentQuestionIndex,
        mittEventEmitter,
    };
};

export default useReviewController;

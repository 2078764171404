import React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps {
    link: string;
    text: string;
    icon: JSX.Element;
}

const NavLinkButton = ({ link, text, icon }: IProps) => {
    return (
        <NavLink
            to={link}
            className="flex flex-col items-center justify-center"
        >
            {({ isActive }) => (
                <>
                    <div
                        className={`flex items-center justify-center size-[42px] rounded-md ${
                            isActive ? 'bg-secondary' : 'bg-transparent'
                        }`}
                    >
                        {React.cloneElement(icon, {
                            className: 'size-[32px] text-white',
                        })}
                    </div>
                    <span
                        className={`mt-[0.2rem] font-bold ${
                            isActive ? 'text-secondary' : 'text-white'
                        }`}
                    >
                        {text}
                    </span>
                </>
            )}
        </NavLink>
    );
};

export default NavLinkButton;

// eslint-disable-next-line @nx/enforce-module-boundaries
// import X from '@auth0/auth0-react';
import { createTRPCReact } from '@trpc/react-query';
import normalizeUrl from 'normalize-url';

import type { AppRouter } from '@backend/trpc/trpcRouter';

import { backendConfig } from './config';

export const TRPC_URL = normalizeUrl(`${backendConfig}/trpc`);

export const trpcReact = createTRPCReact<AppRouter>();

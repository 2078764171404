import CrossSVG from '@clientweb/src/assets/icons/cross.svg';
import { memo } from 'react';
import styled from 'styled-components';

export interface IFullscreenModalPopUpProps {
    className?: string;
    children?: React.ReactNode;
    onClose?: (event: React.MouseEvent<HTMLElement>) => void;
}

const FullscreenModalPopUp = ({
    className,
    children,
    onClose,
}: IFullscreenModalPopUpProps) => {
    return (
        <div className={`${className}`}>
            <div className={`mainPopUp`}>
                {onClose ? (
                    <CloseCrossImg
                        onClick={onClose}
                        alt="cross icon"
                        src={CrossSVG}
                    />
                ) : null}

                {children}
            </div>
        </div>
    );
};

const CloseCrossImg = styled.img``;

const Memoized = memo(FullscreenModalPopUp);
const Styled = styled(Memoized)`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background-color: #0000007d;
    backdrop-filter: blur(2px);

    .mainPopUp {
        position: fixed;
        width: fit-content;
        height: fit-content;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    ${CloseCrossImg} {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        z-index: 10;
    }
`;
export default Styled;

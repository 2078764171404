import { trpcVanilla } from '@clientweb/src/AppRoot/trpcClient.vanilla';
import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import Select from '@clientweb/src/design/v1/dataEntry/Select/Select';
import { Button } from '@clientweb/src/design/v1/general/Button/Button';
import { firebaseClientAppAuth as auth } from '@clientweb/src/firebase.client';
import { zodResolver } from '@hookform/resolvers/zod';
import { memo, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

interface IProps {
    closeModal: () => void;
    handleError: (field: string) => void;
    handleUpdate: () => void;
}

const userPronounSchema = z.object({
    pronoun: z.enum(['he/him', 'she/her', 'they/them', 'other']),
});

const PRONOUNS = ['he/him', 'she/her', 'they/them', 'other'];

type FormDataAdditionalInfo = z.infer<typeof userPronounSchema>;

const UserAdditionalInfoForm = ({
    closeModal,
    handleError,
    handleUpdate,
}: IProps) => {
    const appRootControl = useContext(AppRootContext);
    const [error, setError] = useState<string | null>(null);
    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<FormDataAdditionalInfo>({
        defaultValues: {
            pronoun: undefined,
        },
        resolver: zodResolver(userPronounSchema),
    });

    useEffect(() => {
        const user = appRootControl?.getUserProfileQuery.data;
        if (user?.pronoun) {
            setValue('pronoun', user?.pronoun ?? '');
        }
    }, [appRootControl?.getUserProfileQuery.data]);

    const onSubmit = async (data: FormDataAdditionalInfo) => {
        const userId = auth.currentUser?.uid;

        if (!userId) {
            setError('User not found');
            return;
        }
        try {
            await trpcVanilla.userRouter.v1.updateUserPronoun.mutate({
                userId,
                pronoun: data.pronoun,
            });
        } catch (error) {
            handleError('Pronouns');
        }
        handleUpdate();
        appRootControl?.getUserProfileQuery.refetch();
    };

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-1 mt-4"
        >
            <div className="field">
                <Controller
                    control={control}
                    name="pronoun"
                    render={({ field }) => (
                        <Select
                            {...field}
                            placeholder="SELECT PRONOUN"
                            className="w-full"
                        >
                            {PRONOUNS.map((pronoun) => (
                                <option key={pronoun} value={pronoun}>
                                    {pronoun}
                                </option>
                            ))}
                        </Select>
                    )}
                />
                {errors.pronoun && (
                    <p className="help is-danger font-outfit">
                        Provide pronoun
                    </p>
                )}
            </div>
            {error && <p className="help is-danger font-outfit">{error}</p>}
            <div className="flex gap-2">
                <Button type="button" onClick={closeModal} variant="secondary">
                    CANCEL
                </Button>
                <Button type="submit">SAVE</Button>
            </div>
        </form>
    );
};

const Memoized = memo(UserAdditionalInfoForm);
export default Memoized;

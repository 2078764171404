import { AppRootContext } from '@clientweb/src/AppRoot/useAppRootController';
import { directusFileUrl } from '@clientweb/src/utils/frontend';
import clsx from 'clsx';
import { enqueueSnackbar } from 'notistack';
import { memo, useContext, useState } from 'react';
import styled from 'styled-components';

import { formatDateToShort } from '@common/utils/dateAndTime/formatting';

import CopyIconSVG from '../../assets/content_copy.svg?react';
import { RewardContext } from './Rewards';

const RenderRewards = styled(({ className }) => {
    const rewardsController = useContext(RewardContext);
    const appRootController = useContext(AppRootContext);

    const handleCopyCode = async (code: string) => {
        try {
            await navigator.clipboard.writeText(code);
            enqueueSnackbar('Code copied to clipboard', {
                variant: 'success',
            });
        } catch (error) {
            console.log('error', { error });
            enqueueSnackbar('Unable to copy code');
        }
    };

    return (
        <div className={className}>
            {rewardsController?.getUserRewardsQuery.data?.rewards?.map(
                (item) => {
                    const imageUrl =
                        (() => {
                            return (
                                directusFileUrl(
                                    item?.directus?.reward_template?.product
                                        ?.SampleFestProduct_id?.main_image,
                                ) ?? appRootController?.whiteLabelLogoUrl
                            );
                        })() ?? '';

                    return (
                        <RewardBox
                            key={item.database.id}
                            className="flex flex-col gap-2"
                        >
                            <section className="flex flex-col gap-1">
                                <div className="pb-3">
                                    <img
                                        className={clsx([
                                            'rounded',
                                            item?.database.productId
                                                ? undefined
                                                : `h-[112px]`,
                                        ])}
                                        src={imageUrl}
                                        alt="reward"
                                    />
                                </div>
                                <div className="text-[#03594d] text-2xl font-bold font-['Outfit']">
                                    {
                                        item.directus?.reward_template?.campaign
                                            ?.name
                                    }
                                </div>
                                <span className="font-bold">
                                    Expires &nbsp;
                                    {formatDateToShort(
                                        new Date(
                                            item.directus?.reward_template?.expires,
                                        ),
                                    )}
                                </span>
                            </section>
                            <div className="flex flex-col gap-2">
                                {item?.directus?.barcode ? (
                                    <section className="pt-2">
                                        <p className="pb-1">
                                            Scan this code on the counter TBD
                                        </p>
                                        <div className="bg-white rounded-lg border border-[#03594d] flex justify-center items-center">
                                            <img
                                                alt="barcode"
                                                className="h-32 p-2"
                                                src={
                                                    directusFileUrl(
                                                        item.directus.barcode,
                                                    ) ?? ''
                                                }
                                            />
                                        </div>
                                    </section>
                                ) : null}
                                {item?.directus?.online_code ? (
                                    <section className="pt-2">
                                        <p className="pb-1">
                                            Use this code when you checkout
                                            on&nbsp;
                                            {
                                                appRootController
                                                    ?.getOrganizationWhitelabelConfigQuery
                                                    .data?.name
                                            }
                                        </p>
                                        <div className="relative h-12 p-3 bg-[#e6eeed] rounded justify-start items-center">
                                            <div className="grow basis-0 text-[#03594d] text-base font-bold">
                                                {item.directus.online_code}
                                            </div>
                                            <CopyIconSVG
                                                className="absolute top-1/2 -translate-y-1/2 right-3"
                                                onClick={() =>
                                                    handleCopyCode(
                                                        item?.directus
                                                            ?.online_code ?? '',
                                                    )
                                                }
                                            />
                                        </div>
                                    </section>
                                ) : null}

                                <RenderWYSWIGDescription
                                    className="pt-2"
                                    text={
                                        item.directus?.reward_template
                                            ?.description
                                    }
                                />
                            </div>
                        </RewardBox>
                    );
                },
            )}
        </div>
    );
})`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const MAX_DESCRIPTION_LENGTH = 120;

const RenderWYSWIGDescription = styled(
    ({ className, text }: { text?: string | null; className?: string }) => {
        const [isExpanded, setIsExpanded] = useState(false);
        if (!text?.length) return;
        const isTooLong = text?.length > MAX_DESCRIPTION_LENGTH;

        return (
            <section className={className}>
                <div className="inline">
                    <div
                        className="contents"
                        dangerouslySetInnerHTML={{
                            __html:
                                isTooLong && !isExpanded
                                    ? `${text.substring(
                                          0,
                                          MAX_DESCRIPTION_LENGTH,
                                      )}...`
                                    : text ?? '',
                        }}
                    ></div>
                    {isTooLong && !isExpanded ? (
                        <span
                            onClick={() => setIsExpanded(true)}
                            className="font-bold text-[#03594d]"
                        >
                            Show more
                        </span>
                    ) : null}
                    {isTooLong && isExpanded ? (
                        <span
                            onClick={() => setIsExpanded(false)}
                            className="font-bold text-[#03594d]"
                        >
                            Show less
                        </span>
                    ) : null}
                </div>
            </section>
        );
    },
)``;

const RewardBox = styled.div`
    background-color: #f9fdeb;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1rem;
`;

const Memoized = memo(RenderRewards);
const Styled = styled(Memoized)``;
export default Styled;
